// Corresponding key name from tailwind config theme
const SPACING_THEME_KEY = 'spacing';

// Mapping of shorthand prop name to full CSS property key
const SPACING_MAP = {
    m: 'margin',
    p: 'padding',
    px: ['padding-left', 'padding-right'],
    py: ['padding-top', 'padding-bottom'],
    mx: ['margin-left', 'margin-right'],
    my: ['margin-top', 'margin-bottom'],
    pt: 'padding-top',
    pb: 'padding-bottom',
    pl: 'padding-left',
    pr: 'padding-right',
    mt: 'margin-top',
    mb: 'margin-bottom',
    ml: 'margin-left',
    mr: 'margin-right',
};

// List of keys from the map
const SPACING_KEYS = Object.keys(SPACING_MAP);

export default {
    propKeys: SPACING_KEYS,
    propMap: SPACING_MAP,
    themeKey: SPACING_THEME_KEY,
};
