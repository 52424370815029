import tw, { styled } from 'twin.macro';

export const CountryDropDown = styled.div`
    &:focus-within {
        > div {
            ${tw`relative z-20 ring-1 ring-blue border-blue`};
        }
    }
`;

export const StyledInput = styled.input`
    && {
        ${tw`w-full pl-0 text-sm outline-none border-0 ring-0 rounded-r focus:(border-0 outline-none ring-0)`}
    }
`;
