import * as React from 'react';
import type * as CSS from 'csstype';
import StyledGrid from './styled';
import {
    GridGap,
    GridRowStartKeys,
    GridRowEndKeys,
    GridColStartKeys,
    GridColEndKeys,
    GridTemplateRowsKeys,
    GridTemplateColumnsKeys,
    GridColSpan,
    GridRowSpan,
    BorderColor,
    BorderRadius,
    BorderWidth,
    Spacing,
    Width,
    Height,
    Items,
    Content,
    AlignItems,
} from '../../core/types';

export interface GridProps extends Spacing, Height, Width {
    display?: CSS.Property.Display;

    bgColor?: string;
    borderStyle?: CSS.Property.BorderStyle;
    borderRadius?: keyof BorderRadius;
    borderColor?: keyof BorderColor;
    borderWidth?: keyof BorderWidth;

    // it's a shorthand for justifyItems and alignItems. See the actual CSS property
    placeItems?: Items;

    // CSS justify-items property
    justifyItems?: Items;
    // CSS justify-content property
    justifyContent?: Content;

    // CSS align-content property
    alignContent?: Content;
    // CSS align-items property
    alignItems?: AlignItems;

    // CSS row-gap property
    rowSpan?: GridRowSpan | GridRowSpan[];
    // CSS row-gap property
    colSpan?: GridColSpan | GridColSpan[];

    // CSS row-gap property
    rowGap?: keyof GridGap;
    // CSS column-gap property
    colGap?: keyof GridGap;
    // CSS gap property. Will override rowGap and colGap if they co-exist
    gap?: keyof GridGap;

    // CSS grid-row-start
    rowStart?: GridRowStartKeys | GridRowStartKeys[];
    // CSS grid-row-end
    rowEnd?: GridRowEndKeys | GridRowEndKeys[];
    // CSS grid-column-start
    colStart?: GridColStartKeys | GridColStartKeys[];
    // CSS grid-column-end
    colEnd?: GridColEndKeys | GridColEndKeys[];

    // CSS grid-template-rows
    templateRows?: GridTemplateRowsKeys | GridTemplateRowsKeys[];
    // CSS grid-template-columns
    templateCols?: GridTemplateColumnsKeys | GridTemplateColumnsKeys[];
    childrenCount?: number;
}

export const Grid: React.FC<GridProps> = ({ children, ...rest }) => {
    return <StyledGrid {...rest}>{children}</StyledGrid>;
};

Grid.displayName = 'Grid';
