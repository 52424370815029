import * as React from 'react';

export const InProgress = (props: { alt: string }) => (
    <svg
        {...props}
        role="img"
        aria-label="In Progress"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        width="100%"
        height="100%"
        viewBox="0 0 10 10"
    >
        <circle cx={5} cy={5} r={5} fill="#fff" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 8.889A3.889 3.889 0 1 0 5 1.11 3.889 3.889 0 0 0 5 8.89ZM5 10A5 5 0 1 0 5 0a5 5 0 0 0 0 10Z"
            fill="#1890FF"
        />
        <path d="M8.889 5A3.889 3.889 0 1 1 1.11 5H8.89Z" fill="#1890FF" />
    </svg>
);
