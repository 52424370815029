import space from './space';
import width from './width';
import maxWidth from './maxWidth';
import height from './height';
import maxHeight from './maxHeight';
import minHeight from './minHeight';
import minWidth from './minWidth';
import objectPosition from './objectPosition';
import dynamic from './dynamic';
import gridTemplateRows from './gridTemplateRows';
import gridTemplateColumns from './gridTemplateColumns';
import gridRow from './gridRow';
import gridColumn from './gridColumn';
import gridRowStart from './gridRowStart';
import gridRowEnd from './gridRowEnd';
import gridColumnStart from './gridColumnStart';
import gridColumnEnd from './gridColumnEnd';

export default [
    dynamic,
    space,
    width,
    height,
    maxWidth,
    maxHeight,
    objectPosition,
    minWidth,
    minHeight,
    gridTemplateRows,
    gridTemplateColumns,
    gridRow,
    gridColumn,
    gridRowStart,
    gridRowEnd,
    gridColumnStart,
    gridColumnEnd,
];
