import tw, { css, styled } from 'twin.macro';
import { Grid } from '../Grid';
import { InputGroupProps } from './InputGroup';

function getStyles(childrenCount?: number) {
    switch (childrenCount) {
        case 2:
            return css`
                > {
                    ${tw`first:(rounded-t-md) last:(rounded-b-md -mt-px)`}
                }
            `;

        case 3:
            return css`
                > {
                    ${tw`first:(-mb-px rounded-t-md col-span-2) last:(col-start-2 row-start-2 rounded-br-md -ml-px)`}
                }

                > :nth-child(2) {
                    ${tw`col-span-1 row-start-2 rounded-bl-md`}
                }
            `;
        default:
            return css`
                ${tw`block`};
                > {
                    ${tw`first:(rounded-t-md) last:(rounded-b-md)`}
                }

                > :not(:last-child),
                > :not(:first-child) {
                    ${tw`-mt-px`}
                }
            `;
    }
}

export default styled(Grid)<InputGroupProps & { hasError: boolean }>`
    > * {
        ${tw`overflow-hidden border border-gray-300 focus-within:(ring-1 ring-blue outline-none border-blue relative z-10)`};
        ${({ hasError }) => hasError && tw`border-red-500 focus-within:(ring-red-500 border-red-500)`};
    }

    ${({ childrenCount }) => getStyles(childrenCount)};
`;
