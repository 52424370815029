import * as React from 'react';
import tw from 'twin.macro';
import { XIcon } from '@heroicons/react/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../Button';
import { Colors } from '../../core';
import { withTheme } from '../../theme';

export type NotificationBaseProps = {
    title: React.ReactNode;
    description?: React.ReactNode;
    icon?: React.ReactNode;
    colorTheme?: Colors;
    alignActionButtons?: string;
    styledActionButtons?: boolean;
    singleAction?: () => void;
    singleActionText?: React.ReactNode;
    splitActionOk?: () => void;
    splitActionOkText?: React.ReactNode;
    splitActionCancel?: () => void;
    splitActionCancelText?: React.ReactNode;
    children?: React.ReactNode;
    isDirectMount?: boolean;
    // eslint-disable-next-line no-use-before-define
    notifications?: NotificationType[];
    removeNotification?: (id: number) => void;
};

export interface NotificationType extends NotificationBaseProps {
    id: number;
}

export const Notification: React.FC<Partial<NotificationType>> = withTheme(
    (props: Partial<NotificationType>): JSX.Element => {
        const { notifications = [], removeNotification, isDirectMount, colorTheme: contextColorTheme } = props; // from NotificationContext

        // direct mount Notification (primarily used for Chromatic snapshot)
        if (isDirectMount) notifications.push(props as NotificationType);

        const handleDismiss = (id: number) => {
            removeNotification?.(id);
        };

        const handleAction = (id: number, action: () => void) => {
            action?.();
            handleDismiss(id);
        };

        return (
            <motion.ul css={tw`space-y-3 h-full w-full p-4 fixed top-0 z-50 pointer-events-none`}>
                <AnimatePresence initial={false}>
                    {notifications.map((options: NotificationType) => {
                        const {
                            id,
                            title,
                            description,
                            icon,
                            styledActionButtons,
                            singleAction,
                            singleActionText,
                            splitActionOk,
                            splitActionCancel,
                            splitActionOkText,
                            splitActionCancelText,
                            alignActionButtons = 'right',
                            colorTheme: propColorTheme,
                        } = options;
                        const hasSingleAction = singleAction;
                        const hasSplitActions = splitActionOk || splitActionCancel;
                        const colorTheme = propColorTheme || contextColorTheme; // use direct notification colorTheme override before context
                        return (
                            <motion.li
                                key={id}
                                initial={{ opacity: 0, x: '100%', scale: 0.3 }}
                                animate={{ opacity: 1, x: 0, scale: 1 }}
                                exit={{ opacity: 0, transition: { duration: 0.3 } }}
                                layout
                            >
                                <div css={tw`w-full flex flex-col items-center space-y-4 sm:items-end`}>
                                    <div
                                        css={tw`max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 divide-x divide-gray-200`}
                                    >
                                        <div css={tw`w-0 flex-1 flex items-center p-4`}>
                                            {icon && <div css={tw`flex-shrink-0 pt-0.5 mb-auto`}>{icon}</div>}
                                            <div css={[icon && tw`ml-3`, tw`w-full mb-auto`]}>
                                                {/* title */}
                                                {title && <p css={tw`text-sm font-semibold text-gray-900`}>{title}</p>}
                                                {/* description */}
                                                {description && (
                                                    <p css={tw`mt-1 text-sm text-gray-500`}>{description}</p>
                                                )}
                                                {/* lower buttons */}
                                                {alignActionButtons === 'bottom' &&
                                                    (styledActionButtons ? (
                                                        <div css={tw`w-0 flex-1`}>
                                                            <div css={tw`mt-4 flex space-x-3`}>
                                                                {splitActionOk && (
                                                                    <Button
                                                                        variant="primary"
                                                                        colorTheme={colorTheme}
                                                                        onClick={() => {
                                                                            handleAction(id, splitActionOk);
                                                                        }}
                                                                    >
                                                                        {splitActionOkText}
                                                                    </Button>
                                                                )}
                                                                {splitActionCancel && (
                                                                    <Button
                                                                        variant="outline"
                                                                        colorTheme="gray"
                                                                        onClick={() => {
                                                                            handleAction(id, splitActionCancel);
                                                                        }}
                                                                    >
                                                                        {splitActionCancelText}
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div css={tw`mt-2 flex space-x-7`}>
                                                            {splitActionOk && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleAction(id, splitActionOk);
                                                                    }}
                                                                    css={tw`bg-white rounded-md text-sm font-semibold text-purple-700 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}
                                                                >
                                                                    {splitActionOkText}
                                                                </button>
                                                            )}
                                                            {splitActionCancel && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleAction(id, splitActionCancel);
                                                                    }}
                                                                    css={tw`bg-white rounded-md text-sm font-semibold text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}
                                                                >
                                                                    {splitActionCancelText}
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                            {hasSingleAction && (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        handleAction(id, singleAction);
                                                    }}
                                                    css={tw`mb-auto ml-3 flex-shrink-0 bg-white rounded-md text-sm font-semibold text-purple-700 hover:text-purple-500 focus:(outline-none ring-2 ring-offset-2 ring-purple-500)`}
                                                >
                                                    {singleActionText}
                                                </button>
                                            )}
                                            {/* always display Close X button unless hasSplitActions */}
                                            {!hasSplitActions && (
                                                <div css={tw`ml-4 flex-shrink-0 flex mb-auto`}>
                                                    <button
                                                        type="button"
                                                        css={tw`bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:(outline-none ring-2 ring-offset-2 ring-purple-500)`}
                                                        onClick={() => {
                                                            handleDismiss(id);
                                                        }}
                                                    >
                                                        <span css={tw`sr-only`}>Close</span>
                                                        <XIcon css={tw`h-5 w-5`} aria-hidden="true" />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {/* right side buttons */}
                                        {alignActionButtons === 'right' && hasSplitActions && (
                                            <div css={tw`flex`}>
                                                <div css={tw`flex flex-col divide-y divide-gray-200`}>
                                                    {splitActionOk && (
                                                        <div css={tw`h-0 flex-1 flex`}>
                                                            <button
                                                                type="button"
                                                                css={tw`w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-semibold text-purple-700 hover:text-purple-500 focus:outline-none focus:z-10 focus:ring-2 focus:ring-purple-500`}
                                                                onClick={() => {
                                                                    handleAction(id, splitActionOk);
                                                                }}
                                                            >
                                                                {splitActionOkText}
                                                            </button>
                                                        </div>
                                                    )}
                                                    {splitActionCancel && (
                                                        <div css={tw`h-0 flex-1 flex`}>
                                                            <button
                                                                type="button"
                                                                css={tw`w-full border border-transparent rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-semibold text-gray-700 hover:text-gray-500 focus:(outline-none ring-2 ring-purple-500)`}
                                                                onClick={() => {
                                                                    handleAction(id, splitActionCancel);
                                                                }}
                                                            >
                                                                {splitActionCancelText}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </motion.li>
                        );
                    })}
                </AnimatePresence>
            </motion.ul>
        );
    }
);

Notification.displayName = 'Notification';

export const notification = {
    open: Notification,
};
