import tw, { TwStyle } from 'twin.macro';
import { ButtonSizes } from '.';

export const ICON_SIZE_STYLES: Record<ButtonSizes, TwStyle> = {
    xs: tw`h-4 w-4`,
    sm: tw`h-4 w-4`,
    md: tw`h-5 w-5`,
    lg: tw`h-5 w-5`,
    xl: tw`h-5 w-5`,
};

export const BUTTON_SIZE_STYLES: Record<ButtonSizes, TwStyle> = {
    xs: tw`text-xs px-2.5 py-1.5`,
    sm: tw`text-sm leading-4 px-3 py-2`,
    md: tw`text-sm px-4 py-2`,
    lg: tw`text-base px-4 py-2`,
    xl: tw`text-base px-6 py-3`,
};
