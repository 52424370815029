import * as React from 'react';

export const Cancelled = (props: { alt: string }) => (
    <svg
        {...props}
        role="img"
        aria-label="Cancelled"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        width="100%"
        height="100%"
        viewBox="0 0 10 10"
    >
        <circle cx={5} cy={5} r={5} fill="#fff" />
        <path
            d="M1.461 1.461a5.016 5.016 0 0 1 7.077 0 5.015 5.015 0 0 1 0 7.077 5.016 5.016 0 0 1-7.077 0 5.016 5.016 0 0 1 0-7.077Zm1.567.556 4.954 4.954a3.562 3.562 0 0 0-.454-4.5 3.562 3.562 0 0 0-4.5-.454Zm-1.01 1.011a3.562 3.562 0 0 0 .455 4.5 3.561 3.561 0 0 0 4.498.454L2.017 3.028Z"
            fill="#FF4D4F"
        />
    </svg>
);
