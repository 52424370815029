import * as React from 'react';
import tw from 'twin.macro';
import { XIcon } from '@heroicons/react/solid';
import { disabledStyles } from '../../core/utils';
import {
    getLeftIconStyles,
    getRightIconSizeStyles,
    getSizeStyles,
    getColorThemeStyles,
    getControlIconSizeStyles,
} from './utils';
import { Colors, ButtonSizes } from '../../core';
import { withTheme } from '../../theme';

export type ButtonVariants = 'primary' | 'outline' | 'link' | 'dismiss' | 'ghost' | 'control';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariants;
    size?: ButtonSizes;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    isFullWidth?: boolean;
    isDisabled?: boolean;
    colorTheme?: Colors;
    controlIcon?: React.ReactNode;
};

const baseStyles = tw`inline-flex items-center transition-all whitespace-nowrap font-semibold rounded focus:(outline-none ring-2 ring-offset-2 ring-blue)`;

export const Button = withTheme(
    React.forwardRef<HTMLButtonElement, ButtonProps>(
        (
            {
                children,
                size = 'sm',
                leftIcon,
                rightIcon,
                variant = 'primary',
                isDisabled = false,
                isFullWidth = false,
                colorTheme,
                controlIcon,
                ...rest
            },
            ref
        ) => {
            const isDismiss = variant === 'dismiss';
            return (
                <button
                    type="button"
                    ref={ref}
                    css={[
                        baseStyles,
                        getSizeStyles(size),
                        isDisabled && disabledStyles,
                        isFullWidth && tw`w-full flex justify-center items-center`,
                        colorTheme && getColorThemeStyles(colorTheme, variant),
                    ]}
                    {...rest}
                    disabled={isDisabled}
                >
                    {isDismiss && <XIcon tw="h-3 w-3 m-auto" aria-hidden="true" />}
                    {leftIcon && (
                        <span data-testid="lefticon" css={[getLeftIconStyles(size)]}>
                            {leftIcon}
                        </span>
                    )}
                    {children}
                    {rightIcon && (
                        <span data-testid="righticon" css={[getRightIconSizeStyles(size)]}>
                            {rightIcon}
                        </span>
                    )}
                    {variant === 'control' && (
                        <span css={[getControlIconSizeStyles(size)]} data-testid="controlIcon">
                            {controlIcon}
                        </span>
                    )}
                </button>
            );
        }
    )
);

Button.displayName = 'Button';
