import * as React from 'react';
import { createPortal } from 'react-dom';
import { Notification, NotificationType, NotificationBaseProps } from './Notification';

let notificationIndex = 0;

type NotificationContextType = {
    notifications: NotificationType[];
    addNotification: (options: NotificationBaseProps) => void;
    removeNotification: (id: number) => void;
};

export const NotificationContext = React.createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [notifications, setNotifications] = React.useState<NotificationType[]>([]);

    const addNotification = React.useCallback(
        (options: NotificationBaseProps) => {
            setNotifications([...notifications, { ...options, id: notificationIndex++ }]);
        },
        [notifications]
    );

    const removeNotification = React.useCallback(
        (id: number) => {
            const notificationsWithIdRemoved = notifications.filter((n) => n.id !== id);
            setNotifications(notificationsWithIdRemoved);
        },
        [notifications]
    );

    const notificationData = React.useMemo(() => {
        return { notifications, addNotification, removeNotification };
    }, [notifications, addNotification, removeNotification]);

    return (
        <NotificationContext.Provider value={notificationData}>
            {children}
            {createPortal(<Notification {...notificationData} />, document.body)}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = (): Partial<NotificationContextType> => {
    const context = React.useContext(NotificationContext);
    if (context === undefined)
        throw new Error('useNotificationContext must be within Notification Provider nested in CertnUI Provider');
    return context;
};
