import tw, { TwStyle } from 'twin.macro';
import { ModalSize } from '.';
import { AlignButtonType, ModalType } from './Modal';

// These helpers are used for differing styles between variants
// Any common styles are declared in Modal.tsx directly

export const getVariantStyles = (hasFooter?: boolean, modalSize?: ModalSize): TwStyle[] => {
    const styleArray = [
        tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:(my-8 align-middle w-full)`,
    ];
    if (hasFooter) {
        styleArray.push(tw`sm:max-w-lg`);
    } else {
        switch (modalSize) {
            case 'sm':
                styleArray.push(tw`px-4 pt-5 pb-4 sm:(max-w-sm p-6)`);
                break;
            case 'lg':
            default:
                styleArray.push(tw`px-4 pt-5 pb-4 sm:(max-w-lg p-6)`);
                break;
        }
    }
    return styleArray;
};

export function getButtonDivStyles(hasFooter?: boolean, alignButton?: AlignButtonType): TwStyle[] {
    const styleArray = [];
    if (hasFooter) {
        styleArray.push(tw`bg-gray-100 px-4 py-3 sm:px-6`);
    }
    switch (alignButton) {
        case 'left':
            styleArray.push(tw`mt-5 sm:(mt-4 ml-10 pl-4 flex)`);
            break;
        case 'right':
            styleArray.push(tw`mt-5 sm:(mt-4 flex flex-row-reverse)`);
            break;
        case 'wide-double':
            styleArray.push(tw`mt-5 sm:(mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense)`);
            break;
        case 'wide-single':
        default:
            styleArray.push(tw`mt-5 sm:mt-6`);
    }

    return styleArray;
}

export function getPrimaryButtonStyles(modalState?: ModalType, alignButton?: AlignButtonType): TwStyle[] {
    const styleArray = [];
    if (modalState === 'alert') {
        styleArray.push(tw`bg-red-500 hover:bg-red-700 focus:ring-red-500`);
    } else {
        styleArray.push(tw`bg-green-500 hover:bg-green-700 focus:ring-green-500`);
    }

    if (alignButton === 'wide-double') {
        styleArray.push(tw`sm:col-start-2`);
    } else if (alignButton === 'right') {
        styleArray.push(tw`sm:(ml-3 w-auto)`);
    } else if (alignButton === 'left') {
        styleArray.push(tw`sm:w-auto`);
    }
    return styleArray;
}

export function getSecondaryButtonStyles(alignButton?: AlignButtonType): TwStyle[] {
    const styleArray = [];
    if (alignButton === 'wide-double') {
        styleArray.push(tw`sm:col-start-1`);
    } else {
        styleArray.push(tw`sm:ml-3 sm:w-auto`);
    }
    return styleArray;
}
