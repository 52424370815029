// Corresponding key name from tailwind config theme
const MIN_WIDTH_THEME_KEY = 'minWidth';

// Mapping of shorthand prop name to full CSS property key
const MIN_WIDTH_MAP = {
    minWidth: 'min-width',
};

// List of keys from the map
const MIN_WIDTH_KEYS = Object.keys(MIN_WIDTH_MAP);

export default {
    propKeys: MIN_WIDTH_KEYS,
    propMap: MIN_WIDTH_MAP,
    themeKey: MIN_WIDTH_THEME_KEY,
};
