// Libraries
import * as React from 'react';
import tw from 'twin.macro';
import get from 'lodash.get';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { matchSorter } from 'match-sorter';
import { FixedSizeList as List } from 'react-window';
import { SearchIcon } from '@heroicons/react/solid';

// Utils
import { toKebabCase, disabledStyles } from '../../core/utils';

// Types
import { SearchableOptions } from './types';

// Styles
import {
    baseStyles as inputBaseStyles,
    focusStyles as inputFocusStyles,
    borderStyles as inputBorderStyles,
    shadowStyles as inputShadowStyles,
    errorStyles as inputErrorStyles,
} from '../Input/styled';
import { labelStyle } from './styles';

// Local modules
import { ErrorMessage } from '../ErrorMessage';
import { SearchableSelectProps } from './SearchableSelect';

const SearchableSelectBaseComponent: React.FC<SearchableSelectProps> = (props) => {
    const { options, label, hideLabel, placeholder, isDisabled, onChange, onInputValueChange, hasError, errorMessage } =
        props;

    const initialSelectedItem = options.find((option) => option.defaultSelected);

    const [items, setItems] = React.useState(options);
    const id = toKebabCase(label);

    // If it's used within Certn's Form, use the error message from RHF
    const hasErrors = Boolean(hasError || errorMessage);

    return (
        <div tw="space-y-2">
            <Downshift
                initialSelectedItem={initialSelectedItem}
                // https://github.com/downshift-js/downshift#itemtostring
                // using lodash get to bypass testing (unnecessary to test as it's from the Downshift readme)
                itemToString={(item) => get(item, 'label', '')}
                onChange={(
                    selectedItem: SearchableOptions | null,
                    stateAndHelpers: ControllerStateAndHelpers<SearchableOptions>
                ) => {
                    onChange?.(selectedItem, stateAndHelpers);
                }}
                onInputValueChange={(value) => {
                    const newItems = matchSorter(options, value, {
                        keys: ['label'],
                        // This baseSort function will use the original index of items as the tie breaker
                        // https://github.com/kentcdodds/match-sorter#basesort-functionitema-itemb--1--0--1
                        // We are ignoring the base sort function testing as it's coming straight from the doc.
                        // Also, intentionally turning ternary into if-else statement so that we can ignore it in
                        // code coverage
                        baseSort: (a, b) => {
                            /* istanbul ignore if */
                            if (a.index < b.index) return -1;
                            return 1;
                        },
                    });
                    setItems(newItems);
                    onInputValueChange?.(value);
                }}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    getRootProps,
                    toggleMenu,
                    isOpen,
                    highlightedIndex,
                    selectedItem,
                }) => (
                    <div tw="space-y-1">
                        <label htmlFor={id} css={[hideLabel && tw`sr-only`, labelStyle]} {...getLabelProps()}>
                            <div tw="flex justify-between">
                                <span>{label}</span>
                            </div>
                        </label>

                        <div tw="relative w-full" {...getRootProps(undefined, { suppressRefError: true })}>
                            <div css={[tw`absolute inset-y-0 left-0 pl-3 pr-2 flex items-center pointer-events-none`]}>
                                <SearchIcon css={[tw`w-5 h-5 text-gray-400`, hasErrors && tw`text-red-500`]} />
                            </div>

                            <input
                                {...getInputProps({
                                    isOpen,
                                    placeholder,
                                })}
                                css={[
                                    inputBaseStyles,
                                    inputFocusStyles,
                                    inputBorderStyles,
                                    inputShadowStyles,
                                    hasErrors && inputErrorStyles,
                                    tw`pl-10 placeholder:text-gray-400`,
                                    isDisabled && disabledStyles,
                                ]}
                                disabled={isDisabled}
                                onClick={() => {
                                    toggleMenu();
                                }}
                            />
                        </div>

                        <div
                            {...getMenuProps()}
                            css={[
                                isOpen &&
                                    tw`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`,
                            ]}
                        >
                            {isOpen && items.length ? (
                                <List
                                    innerElementType="ul"
                                    width="100%"
                                    height={items.length < 5 ? items.length * 42 : 200}
                                    itemCount={items.length}
                                    itemSize={35}
                                    itemData={{
                                        items,
                                        getItemProps,
                                        highlightedIndex,
                                        selectedItem,
                                    }}
                                >
                                    {({ index, style }) => {
                                        // const { items, highlightedIndex, selectedItem } = data;
                                        const item = items[index];
                                        const isActive = highlightedIndex === index;
                                        const isSelected = selectedItem === item;

                                        return (
                                            <li
                                                {...getItemProps({
                                                    style,
                                                    item,
                                                    index,
                                                    isSelected,
                                                })}
                                            >
                                                <span
                                                    css={[
                                                        isSelected || isActive
                                                            ? tw`text-white bg-blue-500`
                                                            : tw`text-gray-900`,
                                                        tw`flex cursor-default select-none relative py-2 pl-3 pr-9 items-center`,
                                                    ]}
                                                >
                                                    {item.label}
                                                </span>
                                            </li>
                                        );
                                    }}
                                </List>
                            ) : null}
                        </div>
                    </div>
                )}
            </Downshift>
            {hasErrors && <ErrorMessage text={errorMessage} />}
        </div>
    );
};

SearchableSelectBaseComponent.displayName = 'SearchableSelectBaseComponent';

export default SearchableSelectBaseComponent;
