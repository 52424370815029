import tw, { TwStyle } from 'twin.macro';
import { Colors } from '../../core';

export const COLOR_THEME_STYLES = {
    red: tw`bg-red-100 text-red-700`,
    orange: tw`bg-orange-100 text-orange-700`,
    yellow: tw`bg-yellow-100 text-yellow-700`,
    green: tw`bg-green-100 text-green-700`,
    blue: tw`bg-blue-100 text-blue-700`,
    purple: tw`bg-purple-100 text-purple-700`,
    gray: tw`bg-gray-100 text-gray-700`,
};

export const COLOR_THEME_CLOSE_BUTTON_STYLES = {
    red: tw`text-red-500 hover:(bg-red-200 text-red-500) focus:bg-red-500`,
    orange: tw`text-orange-500 hover:(bg-orange-200 text-orange-500) focus:bg-orange-500`,
    yellow: tw`text-yellow-500 hover:(bg-yellow-200 text-yellow-500) focus:bg-yellow-500`,
    green: tw`text-green-500 hover:(bg-green-200 text-green-500) focus:bg-green-500`,
    blue: tw`text-blue-500 hover:(bg-blue-200 text-blue-500) focus:bg-blue-500`,
    purple: tw`text-purple-500 hover:(bg-purple-200 text-purple-500) focus:bg-purple-500`,
    gray: tw`text-gray-500 hover:(bg-gray-200 text-gray-500) focus:bg-gray-500`,
};

export const getColorThemeStyles = (colorTheme: Colors): TwStyle => COLOR_THEME_STYLES[colorTheme];

export const getColorThemeCloseButtonStyles = (colorTheme: Colors): TwStyle =>
    COLOR_THEME_CLOSE_BUTTON_STYLES[colorTheme];

export const getXPaddingStyles = (isRounded: boolean, isSmall: boolean): TwStyle => {
    if (isRounded && isSmall) return tw`px-2`; // is rounded small
    if (isRounded && !isSmall) return tw`px-2.5`; // is rounded large
    if (!isRounded && isSmall) return tw`px-2.5`; // is primary small
    return tw`px-3`; // is primary large
};
