import { styled } from 'twin.macro';

import { BoxProps } from '.';
import { getThemeValueByKey, getStylesFromProps, getJustifyContentStyles, getAlignItemsStyles } from '../../core/utils';

export default styled.div<BoxProps>`
    background-color: ${({ bgColor }) => getThemeValueByKey(`colors.${String(bgColor)}`)};
    border-radius: ${({ borderRadius }) => getThemeValueByKey(`borderRadius.${String(borderRadius)}`)};
    border-width: ${({ borderWidth }) => getThemeValueByKey(`borderWidth.${String(borderWidth)}`)};
    border-color: ${({ borderColor }) => getThemeValueByKey(`borderColor.${String(borderColor)}`)};
    border-style: ${({ borderStyle }) => borderStyle};
    ${({ alignItems }) => getAlignItemsStyles(alignItems)};
    ${({ justifyContent }) => getJustifyContentStyles(justifyContent)};
    ${(props) => getStylesFromProps(props)};
`;
