import tw, { css } from 'twin.macro';
import { CSSProp } from 'styled-components';
import { Items, Content, AlignItems } from '../types';

export const disabledStyles = tw`pointer-events-none opacity-50`;

export const getPlaceItemsStyles = (placeItems?: Items): CSSProp => {
    switch (placeItems) {
        case 'start':
            return css`
                ${tw`place-items-start`}
            `;
        case 'end':
            return css`
                ${tw`place-items-end`}
            `;
        case 'center':
            return css`
                ${tw`place-items-center`}
            `;
        case 'stretch':
            return css`
                ${tw`place-items-stretch`}
            `;
        default:
            return css``;
    }
};

export const getJustifyItemsStyles = (justifyItems?: Items): CSSProp => {
    switch (justifyItems) {
        case 'start':
            return css`
                ${tw`justify-items-start`}
            `;
        case 'end':
            return css`
                ${tw`justify-items-end`}
            `;
        case 'center':
            return css`
                ${tw`justify-items-center`}
            `;
        case 'stretch':
            return css`
                ${tw`justify-items-stretch`}
            `;
        default:
            return css``;
    }
};

export const getJustifyContentStyles = (justifyContent?: Content): CSSProp => {
    switch (justifyContent) {
        case 'start':
            return css`
                ${tw`justify-start`}
            `;
        case 'end':
            return css`
                ${tw`justify-end`}
            `;
        case 'center':
            return css`
                ${tw`justify-center`}
            `;
        case 'between':
            return css`
                ${tw`justify-between`}
            `;
        case 'around':
            return css`
                ${tw`justify-around`}
            `;
        default:
            return css``;
    }
};

export const getAlignContentStyles = (alignContent?: Content): CSSProp => {
    switch (alignContent) {
        case 'start':
            return css`
                ${tw`content-start`}
            `;
        case 'end':
            return css`
                ${tw`content-end`}
            `;
        case 'center':
            return css`
                ${tw`content-center`}
            `;
        case 'between':
            return css`
                ${tw`content-between`}
            `;
        case 'around':
            return css`
                ${tw`content-around`}
            `;
        default:
            return css``;
    }
};

export const getAlignItemsStyles = (alignItems?: AlignItems): CSSProp => {
    switch (alignItems) {
        case 'start':
            return css`
                ${tw`items-start`}
            `;
        case 'end':
            return css`
                ${tw`items-end`}
            `;
        case 'center':
            return css`
                ${tw`items-center`}
            `;
        case 'baseline':
            return css`
                ${tw`items-baseline`}
            `;
        case 'stretch':
            return css`
                ${tw`items-stretch`}
            `;
        default:
            return css``;
    }
};
