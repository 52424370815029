import * as React from 'react';
import tw from 'twin.macro';
import { Switch } from '@headlessui/react';
import { ToggleContext } from './ToggleContext';
import { disabledStyles } from '../../core/utils';
import { ErrorMessage } from '../ErrorMessage';
import { IconWrapper } from './styled';

export const ToggleBaseComponent: React.FC = () => {
    const toggleContext = React.useContext(ToggleContext);

    if (!toggleContext) return null;

    const {
        label,
        name,
        sublabel,
        isChecked,
        onChange,
        description,
        alignToggle,
        defaultChecked,
        variant,
        hasError,
        errorMessage,
        isDisabled,
        isReadOnly,
        uncheckIcon,
        checkIcon,
        formState,
        ...otherToggleCtxProps
    } = toggleContext;

    const isShortToggle = variant === 'short';
    const isToggleOnTheLeft = alignToggle === 'left';
    // If readonly or disabled, `defaultChecked` determines the checked state and should never change
    // Also disabled state, while showing the toggle state in the UI, note that it won't
    // submit anything in the form. However for "readonly", the value can be submitted
    const isToggleChecked = Boolean(isDisabled || isReadOnly ? defaultChecked : isChecked);
    const errMsg = formState?.errors[name]?.message || (hasError && errorMessage);

    const hasErrors = Boolean(hasError || errMsg);

    return (
        // div wrapper is needed to avoid unintentional extra spacing from <Form />'s grid gap
        <div>
            <Switch.Group as="div" tw="flex items-center justify-between">
                <span css={[tw`flex-grow flex flex-col`, isToggleOnTheLeft && tw`order-1`]}>
                    <Switch.Label as="span" tw="space-x-1" passive>
                        <span tw="text-sm font-semibold text-gray-900">{label}</span>
                        {sublabel && <span tw="text-sm text-gray-500">{sublabel}</span>}
                    </Switch.Label>
                    {description && (
                        <Switch.Description as="span" tw="text-sm text-gray-500">
                            {description}
                        </Switch.Description>
                    )}
                </span>
                {isShortToggle ? (
                    <Switch
                        {...otherToggleCtxProps}
                        checked={isToggleChecked}
                        disabled={isDisabled || isReadOnly}
                        onChange={(val: boolean) => {
                            onChange?.(val);
                        }}
                        className="group"
                        tw="flex-shrink-0 relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:(ring-2 ring-offset-2)"
                        css={[
                            isToggleOnTheLeft && tw`mr-4`,
                            (isDisabled || isReadOnly) && disabledStyles,
                            hasErrors ? tw`focus:ring-red-500` : tw`focus:ring-blue-500`,
                        ]}
                    >
                        <span tw="sr-only">{label}</span>
                        <span
                            aria-hidden="true"
                            css={[
                                isToggleChecked ? tw`bg-green-500` : tw`bg-gray-200`,
                                tw`pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`,
                            ]}
                        />
                        <span
                            aria-hidden="true"
                            css={[
                                isToggleChecked ? tw`translate-x-5` : tw`translate-x-0`,
                                tw`pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`,
                            ]}
                        />
                    </Switch>
                ) : (
                    <Switch
                        {...otherToggleCtxProps}
                        disabled={isDisabled || isReadOnly}
                        checked={isToggleChecked}
                        onChange={(val: boolean) => {
                            onChange?.(val);
                        }}
                        css={[
                            isToggleOnTheLeft && tw`mr-4`,
                            (isDisabled || isReadOnly) && disabledStyles,
                            isToggleChecked ? tw`bg-green-500` : tw`bg-gray-200`,
                            tw`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200`,
                            hasErrors
                                ? tw`ring-2 ring-offset-2 ring-red-500`
                                : tw`focus:(outline-none ring-2 ring-offset-2 ring-blue-500)`,
                        ]}
                    >
                        <span tw="sr-only">{label}</span>
                        <span
                            aria-hidden="true"
                            css={[
                                isToggleChecked ? tw`translate-x-5` : tw`translate-x-0`,
                                tw`pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                            ]}
                        >
                            {(uncheckIcon || checkIcon) && (
                                <>
                                    {uncheckIcon && (
                                        <IconWrapper
                                            data-testid="toggle-uncheck-icon"
                                            css={[
                                                isToggleChecked
                                                    ? tw`opacity-0 ease-out duration-100`
                                                    : tw`opacity-100 ease-in duration-200`,
                                                tw`absolute inset-0 w-full h-full flex items-center justify-center transition-opacity text-gray-400`,
                                            ]}
                                            aria-hidden="true"
                                        >
                                            {uncheckIcon}
                                        </IconWrapper>
                                    )}
                                    {checkIcon && (
                                        <IconWrapper
                                            data-testid="toggle-check-icon"
                                            css={[
                                                isToggleChecked
                                                    ? tw`opacity-100 ease-in duration-200`
                                                    : tw`opacity-0 ease-out duration-100`,
                                                tw`absolute inset-0 w-full h-full flex items-center justify-center transition-opacity text-gray-400`,
                                            ]}
                                            aria-hidden="true"
                                        >
                                            {checkIcon}
                                        </IconWrapper>
                                    )}
                                </>
                            )}
                        </span>
                    </Switch>
                )}
            </Switch.Group>
            {hasErrors && <ErrorMessage text={errMsg} />}
        </div>
    );
};

ToggleBaseComponent.displayName = 'ToggleBaseComponent';
