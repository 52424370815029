// Corresponding key name from tailwind config theme
const WIDTH_THEME_KEY = 'width';

// Mapping of shorthand prop name to full CSS property key
const WIDTH_MAP = {
    w: 'width',
};

// List of keys from the map
const WIDTH_KEYS = Object.keys(WIDTH_MAP);

export default {
    propKeys: WIDTH_KEYS,
    propMap: WIDTH_MAP,
    themeKey: WIDTH_THEME_KEY,
};
