/**
 * This is the error message shown on all "input" components. Not to be exported and used as a standalone
 */

import * as React from 'react';
import { Text } from '../Text';

interface Props {
    text: React.ReactNode;
    color?: string;
}

export const ErrorMessage = ({ text, color = 'red.500', ...rest }: Props): JSX.Element => (
    <Text as="span" size="sm" color={color} role="alert" {...rest}>
        {text}
    </Text>
);
