import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import tw from 'twin.macro';
import { XIcon } from '@heroicons/react/solid';
import { ExclamationIcon, CheckIcon } from '@heroicons/react/outline';
import { getPrimaryButtonStyles, getButtonDivStyles, getSecondaryButtonStyles } from './utils';
import Component from './styled';
import { Button } from '../Button';
import { Box } from '../Box';

export type ModalType = 'success' | 'alert';
export type ModalSize = 'sm' | 'lg';
export type AlignButtonType = 'left' | 'right' | 'wide-double' | 'wide-single';

export type ModalProps = React.HTMLAttributes<HTMLDivElement> & {
    primaryButton?: string;
    secondaryButton?: string;
    hasIcon?: boolean;
    modalType?: ModalType;
    modalSize?: ModalSize;
    hasFooter?: boolean;
    alignButton?: AlignButtonType;
    isDismissable?: boolean;
    isBackdropDismiss?: boolean;
    onClose?: () => void;
    isOpen?: boolean;
};

// Framer Motion params for effects
const fmFadeEffect = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: { delay: 0.5 },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
        },
    },
};
const fmBackdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
    (
        {
            children,
            id,
            hasIcon = true,
            primaryButton,
            secondaryButton,
            modalType = 'success',
            modalSize = 'lg',
            isDismissable = false,
            alignButton = 'right',
            hasFooter = false,
            isBackdropDismiss = true,
            isOpen,
            onClose,
            ...rest
        },
        ref
    ) => {
        const isAlert = modalType === 'alert';
        const icon = <Box>{isAlert ? <ExclamationIcon /> : <CheckIcon />}</Box>;

        return (
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="backdrop"
                        data-testid="backdrop"
                        variants={fmBackdrop}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,0.5)',
                            zIndex: 1,
                        }}
                        onClick={() => (isBackdropDismiss ? onClose?.() : undefined)}
                    >
                        <motion.div
                            className="modal-content-wrapper"
                            variants={fmFadeEffect}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            <Component
                                onClick={(e) => e.stopPropagation()}
                                {...rest}
                                ref={ref}
                                id={id}
                                hasFooter={hasFooter}
                                modalSize={modalSize}
                                onClose={onClose}
                            >
                                {/* X-icon for dismissing */}
                                {isDismissable && (
                                    <div css={[tw`hidden sm:block absolute top-0 right-0 pt-4 pr-4`]}>
                                        <Button
                                            variant="outline"
                                            aria-label="closeIcon"
                                            size="sm"
                                            css={[tw`px-0 py-0 bg-white rounded-md text-gray-500 focus:outline-none`]}
                                            onClick={onClose}
                                        >
                                            <XIcon css={[tw`h-6 w-6 hover:text-gray-500`]} />
                                        </Button>
                                    </div>
                                )}
                                <div css={hasFooter ? tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4` : undefined}>
                                    <div css={isAlert ? tw`sm:flex sm:items-start` : undefined}>
                                        {/* Main modal icon determination */}
                                        {hasIcon && icon && (
                                            <div
                                                css={[
                                                    tw`mx-auto flex items-center justify-center h-12 w-12 rounded-full`,
                                                    isAlert
                                                        ? tw`bg-red-100 flex-shrink-0 sm:mx-0 sm:h-10 sm:w-10`
                                                        : tw`bg-green-100`,
                                                ]}
                                            >
                                                <span
                                                    css={[tw`h-6 w-6`, isAlert ? tw`text-red-500` : tw`text-green-500`]}
                                                >
                                                    {icon}
                                                </span>
                                            </div>
                                        )}
                                        <div
                                            css={[
                                                tw`mt-3 text-center`,
                                                isAlert ? tw`sm:mt-0 sm:ml-4 sm:text-left` : tw`sm:mt-5`,
                                            ]}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                <div css={[getButtonDivStyles(hasFooter, alignButton)]}>
                                    {/* Primary Button */}
                                    {primaryButton && (
                                        <Button
                                            css={[
                                                tw`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm cursor-pointer`,
                                                getPrimaryButtonStyles(modalType, alignButton),
                                            ]}
                                        >
                                            {primaryButton}
                                        </Button>
                                    )}
                                    {/* Secondary Button */}
                                    {secondaryButton && (
                                        <Button
                                            variant="outline"
                                            css={[
                                                tw`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:text-sm`,
                                                getSecondaryButtonStyles(alignButton),
                                            ]}
                                            onClick={onClose}
                                        >
                                            {secondaryButton}
                                        </Button>
                                    )}
                                </div>
                            </Component>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }
);

Modal.displayName = 'Modal';
