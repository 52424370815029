import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '../Grid';
import { Rules } from '../Form';
import { ErrorMessage } from '../ErrorMessage';

export interface CheckboxGroupProps {
    defaultValues?: string[];
    name?: string;
    rules?: Rules;
}

export const CheckboxGroupContext = React.createContext<CheckboxGroupProps | undefined>(undefined);

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ children, defaultValues, name = '', rules }) => {
    const formContext = useFormContext();

    const errMsg = formContext?.formState.errors?.[name]?.message;
    const value = React.useMemo(() => {
        return { defaultValues, name, rules };
    }, [defaultValues, name, rules]);

    return (
        <CheckboxGroupContext.Provider value={value}>
            <Grid gap="3.5">{children}</Grid>
            {errMsg && <ErrorMessage text={errMsg} />}
        </CheckboxGroupContext.Provider>
    );
};

CheckboxGroup.displayName = 'CheckboxGroup';
