import { mapColSpanOrRowSpan } from '../../components/Grid/utils';

// Corresponding key name from tailwind config theme
const GRID_ROW_THEME_KEY = 'gridRow';

// Mapping of shorthand prop name to full CSS property key
const GRID_ROW_MAP = {
    rowSpan: 'grid-row',
};

// List of keys from the map
const GRID_ROW_KEYS = Object.keys(GRID_ROW_MAP);

export default {
    propKeys: GRID_ROW_KEYS,
    propMap: GRID_ROW_MAP,
    themeKey: GRID_ROW_THEME_KEY,
    valueFormatterFn: mapColSpanOrRowSpan,
};
