import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectBaseProps, Primary, PrimaryOptions } from './types';
import PrimarySelectBaseComponent from './PrimarySelectBaseComponent';

// onChange types from Select can be from Primary, Branded, Searchable or Native, and they have different shapes
// We remove it here first and then add it back as PrimaryOptions
export type PrimarySelectProps = Omit<SelectBaseProps & Primary, 'variant' | 'onChange'> & {
    onChange?: (option: PrimaryOptions) => void;
};

export const buttonStyle = tw`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:(outline-none ring-1 ring-blue-500 border-blue-500) sm:text-sm`;

const PrimarySelect: React.FC<PrimarySelectProps> = (props) => {
    const { name, rules, onChange } = props;

    const formContext = useFormContext();
    const isUsedWithinCertnForm = formContext;

    if (isUsedWithinCertnForm) {
        const { control } = formContext;
        const errMsg = formContext.formState.errors?.[name]?.message;

        return (
            <Controller
                control={control}
                rules={rules}
                name={name}
                render={({ field: { onChange: RHFOnChange } }) => {
                    const onChangeCB = (option: PrimaryOptions) => {
                        RHFOnChange(option.value);
                        onChange?.(option);
                    };
                    const baseCompProps = { ...props, onChange: onChangeCB, errorMessage: errMsg };
                    return <PrimarySelectBaseComponent {...baseCompProps} />;
                }}
            />
        );
    }

    return <PrimarySelectBaseComponent {...props} />;
};

PrimarySelect.displayName = 'PrimarySelect';

export default PrimarySelect;
