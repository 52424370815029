// Corresponding key name from tailwind config theme
const OBJECT_POSITION_THEME_KEY = 'objectPosition';

// Mapping of shorthand prop name to full CSS property key
const OBJECT_POSITION_MAP = {
    objPos: 'object-position',
};

// List of keys from the map
const OBJECT_POSITION_KEYS = Object.keys(OBJECT_POSITION_MAP);

export default {
    propKeys: OBJECT_POSITION_KEYS,
    propMap: OBJECT_POSITION_MAP,
    themeKey: OBJECT_POSITION_THEME_KEY,
};
