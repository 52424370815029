import tw, { TwStyle } from 'twin.macro';
import { LinkFontWeight } from './Link';
import { Colors } from '../../core';

const FONT_WEIGHT_STYLES: Record<LinkFontWeight, TwStyle> = {
    semibold: tw`font-semibold`,
    bold: tw`font-bold`,
    regular: tw`font-normal`,
};

const COLOR_THEME_STYLES: Record<Colors, TwStyle> = {
    red: tw`text-red-500 hover:text-red-700`,
    orange: tw`text-orange-500 hover:text-orange-700`,
    yellow: tw`text-yellow-500 hover:text-yellow-700`,
    green: tw`text-green-500 hover:text-green-700`,
    blue: tw`text-blue-500 hover:text-blue-700`,
    purple: tw`text-purple-500 hover:text-purple-700`,
    gray: tw`text-gray-500 hover:text-gray-700`,
};

export const getFontWeightStyles = (fontWeight?: LinkFontWeight): TwStyle | undefined =>
    fontWeight && FONT_WEIGHT_STYLES[fontWeight];

export const getColorThemeStyles = (colorTheme: Colors): TwStyle => colorTheme && COLOR_THEME_STYLES[colorTheme];
