import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';

import { Text } from '../Text';
import { Box } from '../Box';
import { Rules } from '../Form';
import { ErrorMessage } from '../ErrorMessage';
import { baseStyles, borderStyles, errorStyles, focusStyles, shadowStyles } from '../Input/styled';
import { toKebabCase } from '../../core/utils';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label: string;
    // hidden for sr-only
    hideLabel?: boolean;
    resizable?: boolean;
    name: string;
    defaultValue?: string;
    hasError?: boolean;
    errorMessage?: React.ReactNode;
    rules?: Rules;
    readonly?: boolean;
    helperText?: React.ReactNode;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            label,
            hideLabel,
            name,
            readonly,
            disabled,
            resizable = true,
            defaultValue,
            placeholder,
            hasError = false,
            errorMessage,
            rules,
            helperText,
            ...rest
        },
        ref
    ) => {
        const formContext = useFormContext();
        const isUsedWithinCertnForm = formContext;

        // props from react hook form
        let propsFromRHF = {};
        let errMsg = errorMessage;

        if (isUsedWithinCertnForm) {
            const { register } = formContext;

            errMsg = formContext.formState.errors?.[name]?.message;

            const { ref: rhfRef, ...others } = register(name, rules);
            propsFromRHF = {
                ref: (e: HTMLInputElement) => {
                    rhfRef(e);
                    if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        (ref as React.MutableRefObject<HTMLInputElement | null>).current = e;
                    }
                },
                ...others,
            };
        }

        const hasErrors = Boolean(hasError || errMsg);

        const id = toKebabCase(name);
        return (
            <Box>
                <label htmlFor={id} css={[tw`block text-sm font-semibold text-gray-700 leading-none`]}>
                    <Box css={[hideLabel && tw`sr-only`, tw`mb-2`]}>
                        <Text as="span">{label}</Text>
                    </Box>
                    <textarea
                        {...rest}
                        id={id}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={readonly}
                        css={[
                            baseStyles,
                            focusStyles,
                            borderStyles,
                            shadowStyles,
                            !resizable && tw`resize-none`,
                            hasErrors && errorStyles,
                        ]}
                        {...propsFromRHF}
                    />
                </label>
                {(errMsg ?? helperText) && (
                    <Box mt="1.5">
                        <ErrorMessage text={errMsg ?? helperText} color={hasErrors ? 'red.500' : 'gray.500'} />
                    </Box>
                )}
            </Box>
        );
    }
);

TextArea.displayName = 'TextArea';
