// Corresponding key name from tailwind config theme
const MIN_HEIGHT_THEME_KEY = 'minHeight';

// Mapping of shorthand prop name to full CSS property key
const MIN_HEIGHT_MAP = {
    minHeight: 'min-height',
};

// List of keys from the map
const MIN_HEIGHT_KEYS = Object.keys(MIN_HEIGHT_MAP);

export default {
    propKeys: MIN_HEIGHT_KEYS,
    propMap: MIN_HEIGHT_MAP,
    themeKey: MIN_HEIGHT_THEME_KEY,
};
