import tw, { TwStyle } from 'twin.macro';

export type Variant = 'info' | 'success' | 'warning' | 'error';

type ColorTheme = 'blue' | 'green' | 'yellow' | 'red';

export const VariantColors: Record<Variant, ColorTheme> = {
    info: 'blue',
    success: 'green',
    warning: 'yellow',
    error: 'red',
};

const VariantDismissStyles: Record<Variant, TwStyle> = {
    info: tw`bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-100`,
    success: tw`bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-100`,
    warning: tw`bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-100`,
    error: tw`bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-100`,
};

export const getDismissButtonVariantStyle = (variant: Variant): TwStyle => variant && VariantDismissStyles[variant];
