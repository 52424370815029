import * as React from 'react';
import tw from 'twin.macro';
import { InformationCircleIcon, CheckCircleIcon, ExclamationIcon, XCircleIcon, XIcon } from '@heroicons/react/solid';
import { Variant, VariantColors, getDismissButtonVariantStyle } from './utils';
import { Button } from '../Button';
import { Wrapper, IconWrapper, MessageWrapper, DescriptionWrapper } from './styled';
import { Link } from '../Link';

export type MessageProps = {
    title?: React.ReactNode;
    description?: React.ReactNode;
    variant?: Variant;
    confirmButtonText?: React.ReactNode;
    closeButtonText?: React.ReactNode;
    onConfirm?: () => void;
    onClose?: () => void;
    hasLeftAccent?: boolean;
    hasDismiss?: boolean;
    linkURL?: string;
    isLinkExternal?: boolean;
    linkText?: React.ReactNode;
    children?: React.ReactNode;
};

const iconLookup: Record<Variant, () => JSX.Element> = {
    info: () => <InformationCircleIcon css={tw`h-5 w-5`} ara-hidden="true" />,
    success: () => <CheckCircleIcon css={tw`h-5 w-5`} ara-hidden="true" />,
    warning: () => <ExclamationIcon css={tw`h-5 w-5`} ara-hidden="true" />,
    error: () => <XCircleIcon css={tw`h-5 w-5`} ara-hidden="true" />,
};

export const Message = React.forwardRef<HTMLDivElement, MessageProps>(
    (
        {
            children,
            title,
            description,
            confirmButtonText,
            closeButtonText,
            onConfirm,
            onClose,
            variant = 'info',
            hasLeftAccent = false,
            hasDismiss = false,
            linkURL,
            linkText,
            isLinkExternal = true,
        },
        ref
    ) => {
        // Dismiss state
        const [isVisible, setIsVisible] = React.useState(true);
        if (!isVisible) return null;

        const dismiss = () => setIsVisible(false);

        // Actions - onClose also dismisses message
        const handleClose = () => {
            onClose?.();
            dismiss();
        };

        // Variant Color
        const color = VariantColors[variant];

        // Variant Icon
        const icon = iconLookup[variant]();

        return (
            <Wrapper
                ref={ref}
                $color={color}
                hasLeftAccent={hasLeftAccent}
                css={[tw`rounded-md p-5 w-full`, hasLeftAccent && tw`rounded-l-none rounded-r-md border-l-4`]}
            >
                <div tw="flex">
                    <IconWrapper $color={color} css={tw`flex-shrink-0`}>
                        {icon}
                    </IconWrapper>
                    <div tw="flex w-full">
                        <div tw="ml-3 flex-1 md:(flex justify-between)">
                            <div tw="flex-col">
                                <MessageWrapper $color={color} css={tw`text-sm font-semibold`}>
                                    {title || children}
                                </MessageWrapper>
                                {description && (
                                    <DescriptionWrapper $color={color} css={tw`mt-2 text-sm`}>
                                        {description}
                                    </DescriptionWrapper>
                                )}
                                {(confirmButtonText || closeButtonText) && (
                                    <div tw="flex w-full space-x-3 mt-1 relative -left-3">
                                        {confirmButtonText && (
                                            <Button colorTheme={color} variant="ghost" onClick={onConfirm}>
                                                {confirmButtonText}
                                            </Button>
                                        )}
                                        {closeButtonText && (
                                            <Button colorTheme={color} variant="ghost" onClick={handleClose}>
                                                {closeButtonText}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                            {linkURL && (
                                <div css={tw`text-sm`}>
                                    <Link
                                        href={linkURL}
                                        isExternal={isLinkExternal}
                                        hasArrow={!hasDismiss}
                                        colorTheme={color}
                                        css={tw`whitespace-nowrap`}
                                    >
                                        {linkText}
                                    </Link>
                                </div>
                            )}
                        </div>
                        {hasDismiss && (
                            <div tw="-mx-1.5 -my-1.5">
                                <button
                                    onClick={dismiss}
                                    type="button"
                                    css={[
                                        tw`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ml-5`,
                                        getDismissButtonVariantStyle(variant),
                                    ]}
                                >
                                    <span tw="sr-only">Dismiss</span>
                                    <XIcon tw="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Wrapper>
        );
    }
);

Message.displayName = 'Message';
