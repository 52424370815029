import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext, FieldErrors } from 'react-hook-form';
import isEmpty from 'lodash.isempty';

import { Box } from '../Box';
import { Text } from '../Text';
import StyledGrid from './styled';
import { ErrorMessage } from '../ErrorMessage';
/**
 * This is to control the children styles. If we want to add support of a component to be
 * used within this InputGroup, that component needs to consume the context, and remove the styles
 * accordingly. See `Input.tsx` for reference.
 */
type Context = {
    withInputGroup: boolean;
};

export type InputGroupProps = {
    label?: string;
    name?: string;
};

export const InputGroupContext = React.createContext<Context | undefined>(undefined);

const value = {
    withInputGroup: true,
};

export const InputGroup: React.FC<InputGroupProps> = ({ children, label }) => {
    /**
     * So far it only support two variations - 2 children or 3.
     * If more than 3 children, it will be stacked
     */
    const childrenCount = React.Children.count(children);
    const formContext = useFormContext();

    const errMsg: FieldErrors = formContext?.formState.errors || {};

    const hasErrors = !isEmpty(errMsg);

    return (
        <InputGroupContext.Provider value={value}>
            <Box>
                {label && (
                    <Text size="sm" fontWeight="semibold" color="gray.700" css={[tw`mb-1`]}>
                        {label}
                    </Text>
                )}

                <StyledGrid hasError={hasErrors} childrenCount={childrenCount}>
                    {children}
                </StyledGrid>
                {hasErrors && (
                    <Box mt="2">
                        {Object.keys(errMsg).map(
                            (item) => errMsg[item]?.message && <ErrorMessage key={item} text={errMsg[item].message} />
                        )}
                    </Box>
                )}
            </Box>
        </InputGroupContext.Provider>
    );
};

InputGroup.displayName = 'InputGroup';
