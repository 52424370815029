import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import tw from 'twin.macro';
import { Box } from '../Box';
import { Text } from '../Text';
import { CheckboxGroupContext } from '../CheckboxGroup';
import { Rules, generateNameWithPrefix } from '../Form';
import { ErrorMessage } from '../ErrorMessage';
import Input from './styled';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: React.ReactNode;
    name?: string; // optional, the name will be supplied via Checkbox Group if used along with it
    description?: React.ReactNode;
    hasError?: boolean;
    errorMessage?: React.ReactNode;
    defaultChecked?: boolean;
    trailingCheckbox?: boolean;
    rules?: Rules;
    isDisabled?: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            label,
            name,
            hasError,
            errorMessage,
            description,
            rules,
            defaultChecked,
            trailingCheckbox,
            isDisabled = false,
            ...rest
        },
        ref
    ) => {
        const checkboxGroupContext = React.useContext(CheckboxGroupContext);
        const checkboxName = checkboxGroupContext?.name ?? (name || generateNameWithPrefix('checkbox-'));
        const checkboxRules = checkboxGroupContext?.rules ?? rules;
        const initialValue = checkboxGroupContext?.defaultValues?.includes(checkboxName) || defaultChecked;
        const formContext = useFormContext();
        const isUsedWithinCertnForm = formContext;

        // props from react hook form
        let propsFromRHF = {};

        let errMsg = errorMessage;

        if (isUsedWithinCertnForm) {
            const { register } = formContext;

            errMsg = formContext.formState.errors?.[checkboxName]?.message;

            const { ref: rhfRef, ...others } = register(checkboxName, checkboxRules);
            propsFromRHF = {
                ref: (e: HTMLInputElement) => {
                    rhfRef(e);
                    if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        (ref as React.MutableRefObject<HTMLInputElement | null>).current = e;
                    }
                },
                ...others,
            };
        }

        const hasErrors = Boolean(hasError || errMsg);

        return (
            <>
                <Box as="label" display="flex">
                    <Box css={[tw`flex items-center h-5`, trailingCheckbox && tw`ml-3 order-1`]}>
                        <Input
                            {...rest}
                            defaultChecked={Boolean(initialValue)}
                            type="checkbox"
                            hasError={hasErrors}
                            name={checkboxName}
                            disabled={isDisabled}
                            {...propsFromRHF}
                        />
                    </Box>
                    <Box css={[!trailingCheckbox && tw`ml-3`]}>
                        <Text size="sm" fontWeight="semibold" color="gray.700">
                            {label}
                        </Text>
                        {description && (
                            <Text size="sm" color="gray.500">
                                {description}
                            </Text>
                        )}
                    </Box>
                </Box>

                {/* Checkbox group will handle display of error message */}
                {!checkboxGroupContext && errMsg && (
                    <Box mt={!formContext ? '2' : '0'}>
                        <ErrorMessage text={errMsg} />
                    </Box>
                )}
            </>
        );
    }
);

Checkbox.displayName = 'Checkbox';
