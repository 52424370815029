import tw, { styled } from 'twin.macro';
import { GridProps } from '.';
import {
    getStylesFromProps,
    getThemeValueByKey,
    getJustifyContentStyles,
    getJustifyItemsStyles,
    getPlaceItemsStyles,
    getAlignContentStyles,
    getAlignItemsStyles,
} from '../../core/utils';

export default styled.div<GridProps>`
    ${tw`grid`}
    background-color: ${({ bgColor }) => getThemeValueByKey(`colors.${bgColor}`)};
    border-radius: ${({ borderRadius }) => getThemeValueByKey(`borderRadius.${borderRadius}`)};
    border-width: ${({ borderWidth }) => getThemeValueByKey(`borderWidth.${borderWidth}`)};
    border-color: ${({ borderColor }) => getThemeValueByKey(`borderColor.${borderColor}`)};

    grid-row-gap: ${({ rowGap }) => getThemeValueByKey(`gap.${rowGap}`)};
    grid-column-gap: ${({ colGap }) => getThemeValueByKey(`gap.${colGap}`)};
    gap: ${({ gap }) => getThemeValueByKey(`gap.${gap}`)};

    ${({ justifyContent }) => getJustifyContentStyles(justifyContent)};
    ${({ justifyItems }) => getJustifyItemsStyles(justifyItems)};
    ${({ alignContent }) => getAlignContentStyles(alignContent)};
    ${({ alignItems }) => getAlignItemsStyles(alignItems)};
    ${({ placeItems }) => getPlaceItemsStyles(placeItems)};

    ${(props) => getStylesFromProps(props)}
`;
