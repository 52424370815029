import tw, { styled } from 'twin.macro';
import { Box } from '../Box';

export const baseStyles = tw`w-full px-3 py-2 text-sm text-gray-900 placeholder-gray-400 disabled:(bg-gray-100 cursor-not-allowed)`;
export const focusStyles = tw`focus:(ring-1 ring-blue outline-none border-blue)`;
export const borderStyles = tw`border border-gray-300 rounded-md`;
export const shadowStyles = tw`shadow-sm`;
export const errorStyles = tw`border-red-500 text-red-500 focus:(ring-red-500 border-red-500 text-red-700)`;

export const StyledInput = styled.input<{
    withInputGroup?: boolean;
    addonBefore?: React.ReactNode;
    addonAfter?: React.ReactNode;
    hasError?: boolean;
}>`
    && {
        ${baseStyles};

        ${({ withInputGroup }) => (withInputGroup ? tw`border-0` : borderStyles)};
        ${({ withInputGroup }) => !withInputGroup && focusStyles};
        ${({ hasError }) => hasError && errorStyles};
        ${({ withInputGroup }) => !withInputGroup && shadowStyles};
        ${({ addonBefore }) => addonBefore && tw`rounded-l-none mt-1`};
        ${({ addonAfter }) => addonAfter && tw`rounded-r-none mt-1`};
    }
`;

export const AddonAfter = styled(Box)`
    > * {
        ${tw`rounded-l-none`}

        &:hover {
            ${tw`bg-gray-100`}
        }
        &:focus {
            ${tw`transition-none ring-transparent ring-0 ring-offset-0 border-none`}
        }
    }
`;
