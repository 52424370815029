import * as React from 'react';
import { styled } from 'twin.macro';

import { getAlignStyles, getCasingStyles, getSizeStyles, getFontWeightStyles } from './utils';
import { getThemeValueByKey } from '../../core/utils';

export type TextSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';

export type Align = 'left' | 'center' | 'right' | 'justify';
export type Casing = 'normal' | 'capitalize' | 'uppercase' | 'lowercase';
export type FontWeight = 'regular' | 'semibold' | 'bold';

// This TextOwnProps and TextProps are to allow polymorphic component.
// When the "as" prop is passed, TS will prompt errors if invalid attr / props are passed.
export type TextOwnProps<E extends React.ElementType = React.ElementType> = {
    as?: E;
    color?: string;
    size?: TextSize;
    fontWeight?: FontWeight;
    align?: Align;
    casing?: Casing;
    children: React.ReactNode;
};

export type TextProps<E extends React.ElementType> = TextOwnProps<E> &
    Omit<React.ComponentProps<E>, keyof TextOwnProps>;

const defaultElement = 'div';

const Element = styled.span<Partial<{ $color: string }>>`
    display: inherit;
    color: ${({ $color }) => getThemeValueByKey(`colors.${$color}`)};
`;

export const Text = <E extends React.ElementType = typeof defaultElement>(props: TextProps<E>): JSX.Element => {
    const { children, as, casing, size, fontWeight, align, color, ...rest } = props;
    const CustomTag = as || defaultElement;

    return (
        <CustomTag {...rest}>
            {/* Need one extra span for styling. Otherwise there'll be lots of TS errors with Styled Components */}
            <Element
                $color={color}
                css={[
                    getAlignStyles(align),
                    getCasingStyles(casing),
                    getFontWeightStyles(fontWeight),
                    getSizeStyles(size),
                ]}
            >
                {children}
            </Element>
        </CustomTag>
    );
};

Text.displayName = 'Text';
