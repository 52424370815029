import * as React from 'react';
import tw from 'twin.macro';
import { OrderStatus } from '../../core';
import { CarretDown, CarretUp } from '../../icons/components';
import { Badge } from '../Badge';
import { Box } from '../Box';
import { Button } from '../Button';
import { Checks, StatusBadge, TranslatedText as StatusBadgeTranslatedText } from '../StatusBadge';
import { Text } from '../Text';
import { Details, Notes, Wrapper } from './styled';

export type DetailItem = {
    id: string;
    name: string | null;
    status: string;
};

export type DrawerCardTranslatedText = {
    statusBadgeText: StatusBadgeTranslatedText;
    showAll: string | JSX.Element;
    showLess: string | JSX.Element;
    notesText?: string | React.ReactNode;
};

export type DrawerCardProps = {
    orderStatus: OrderStatus;
    translatedText: DrawerCardTranslatedText;
    detailsList?: DetailItem[];
    maxDetailsLength?: number;
    checks?: Checks[];
    isCardUnresponsive?: boolean;
    isNotesVisible?: boolean;
};

export const DrawerCard = ({
    detailsList = [],
    maxDetailsLength = 5,
    orderStatus,
    translatedText,
    checks,
    isCardUnresponsive = false,
    isNotesVisible = false,
}: DrawerCardProps) => {
    const [isShowAll, setIsShowAll] = React.useState<boolean>(false);
    const renderDetailItem = (detailItem: DetailItem) => {
        if (detailItem.name) {
            return (
                <Box
                    display="flex"
                    justifyContent="between"
                    alignItems="baseline"
                    css={tw`py-3 border-t border-gray-200 first:border-none`}
                    key={detailItem.id}
                    data-testid="drawer-card-detail-row"
                >
                    <Text css={tw`max-w-[50%]`}>{detailItem.name}</Text>
                    <Text css={tw`text-gray-500`} size="xs">
                        {detailItem.status}
                    </Text>
                </Box>
            );
        }
    };

    const isFullDetailsListVisible = maxDetailsLength < detailsList?.length && isShowAll;

    const isShowButtonVisible = maxDetailsLength < detailsList?.length;

    const toggleShowButton = () => {
        setIsShowAll(!isShowAll);
    };

    const getBadgeColorTheme = isCardUnresponsive ? 'gray' : 'yellow';

    return (
        <Wrapper css={tw` border border-gray-200 bg-white mb-4 last:mb-0`}>
            <div data-testid="status-badge-wrapper" css={isNotesVisible ? tw`p-4 pb-0` : tw`p-4`}>
                <StatusBadge
                    orderStatus={orderStatus}
                    checks={checks}
                    translatedText={translatedText.statusBadgeText}
                    variant="header"
                />
            </div>
            {isNotesVisible && (
                <Notes css={tw`px-4 py-3`}>
                    <Badge
                        paddingSize="sm"
                        variant="rounded"
                        colorTheme={getBadgeColorTheme}
                        size="lg"
                        customFontSize="13px"
                    >
                        {translatedText.notesText}
                    </Badge>
                </Notes>
            )}
            <Details css={tw`border-t border-gray-200  px-4`}>
                {detailsList?.slice(0, maxDetailsLength).map((detailItem: DetailItem) => renderDetailItem(detailItem))}

                {isFullDetailsListVisible &&
                    detailsList?.slice(maxDetailsLength).map((detailItem: DetailItem) => renderDetailItem(detailItem))}
            </Details>
            {isShowButtonVisible && (
                <Box css={tw`px-2`}>
                    <Button
                        variant="ghost"
                        colorTheme="gray"
                        isFullWidth
                        onClick={toggleShowButton}
                        css={tw`border-radius[3px] mb-2 flex items-center`}
                        data-testid="show-more-button"
                    >
                        <Text size="xs" css={tw`font-semibold text-gray-800 mr-1`}>
                            {isFullDetailsListVisible ? translatedText.showLess : translatedText.showAll}
                        </Text>
                        {isFullDetailsListVisible ? (
                            <span data-testid="carret-up">
                                <CarretUp />
                            </span>
                        ) : (
                            <CarretDown data-testid="carret-down" />
                        )}
                    </Button>
                </Box>
            )}
        </Wrapper>
    );
};
