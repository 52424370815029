import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';
import { EyeIcon, EyeOffIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { Box } from '../Box';
import { Text } from '../Text';
import { StyledInput, AddonAfter } from './styled';
import { toKebabCase } from '../../core/utils';
import { InputGroupContext } from '../InputGroup';
import { Rules } from '../Form';
import { ErrorMessage } from '../ErrorMessage';

export interface InputProps {
    label: string;
    // hidden for sr-only
    hideLabel?: boolean;
    name: string;
    defaultValue?: string;
    placeholder?: string;
    isDisabled?: boolean;
    hasError?: boolean;
    errorMessage?: React.ReactNode;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    addonBefore?: React.ReactNode;
    addonAfter?: React.ReactNode;
    helperText?: React.ReactNode;
    cornerHint?: React.ReactNode;
    rules?: Rules;
    type?: 'text' | 'password' | 'number' | 'tel' | 'email';
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            hideLabel,
            name,
            defaultValue,
            placeholder,
            isDisabled = false,
            hasError = false,
            errorMessage,
            prefix,
            suffix,
            addonBefore,
            addonAfter,
            helperText,
            cornerHint,
            type = 'text',
            rules,
            ...rest
        },
        ref
    ) => {
        const id = toKebabCase(label);
        const isPasswordInput = type === 'password';
        const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
        const [prefixWidth, setPrefixWidth] = React.useState(0);
        const [suffixWidth, setSuffixWidth] = React.useState(0);

        const inputGroupContext = React.useContext(InputGroupContext);
        const formContext = useFormContext();
        const isUsedWithinCertnForm = formContext;

        // props from react hook form
        let propsFromRHF = {};

        let errMsg = errorMessage;

        if (isUsedWithinCertnForm) {
            const { register } = formContext;
            errMsg = formContext.formState.errors?.[name]?.message;

            const { ref: rhfRef, ...others } = register(name, rules);
            propsFromRHF = {
                ref: (e: HTMLInputElement) => {
                    rhfRef(e);
                    if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        (ref as React.MutableRefObject<HTMLInputElement | null>).current = e;
                    }
                },
                ...others,
            };
        }

        const hasErrors = Boolean(hasError || errMsg);

        let inputType = type;
        let trailingIcon = suffix;

        if (isPasswordInput) {
            if (isPasswordVisible) {
                inputType = 'text';
                trailingIcon = <EyeOffIcon tw="h-5 w-5" />;
            } else {
                inputType = 'password';
                trailingIcon = <EyeIcon tw="h-5 w-5" />;
            }
        }

        if (hasErrors && !isPasswordInput) {
            trailingIcon = <ExclamationCircleIcon tw="h-5 w-5" />;
        }

        const prefixRef = React.useCallback((node) => {
            if (node !== null) {
                setPrefixWidth(node.getBoundingClientRect().width);
            }
        }, []);
        const suffixRef = React.useCallback((node) => {
            if (node !== null) {
                setSuffixWidth(node.getBoundingClientRect().width);
            }
        }, []);

        let style = {};
        if (prefix) {
            style = { paddingLeft: `${prefixWidth}px` };
        }
        if (trailingIcon) {
            style = { ...style, paddingRight: `${suffixWidth}px` };
        }

        return (
            <Box>
                <label
                    htmlFor={id}
                    css={[hideLabel && tw`sr-only`, tw`block text-sm font-semibold text-gray-700 mb-1`]}
                >
                    <Box display="flex" justifyContent="between">
                        <Text as="span">{label}</Text>
                        {cornerHint && (
                            <Text as="span" color="gray.500">
                                {cornerHint}
                            </Text>
                        )}
                    </Box>
                </label>

                <Box display="flex">
                    {addonBefore && (
                        <Box
                            display="flex"
                            alignItems="center"
                            px="2.5"
                            tw="bg-gray-100 text-sm mt-1 text-gray-500 px-3 rounded rounded-r-none border border-r-0 border-gray-300 pointer-events-none"
                        >
                            {addonBefore}
                        </Box>
                    )}
                    <Box tw="relative w-full">
                        {prefix && (
                            <Box
                                ref={prefixRef}
                                css={[
                                    addonAfter && tw`mt-1`,
                                    tw`absolute inset-y-0 left-0 pl-3 pr-2 flex items-center pointer-events-none`,
                                ]}
                            >
                                <Text as="span" size="sm" color="gray.400">
                                    {prefix}
                                </Text>
                            </Box>
                        )}
                        <StyledInput
                            {...rest}
                            defaultValue={defaultValue}
                            id={id}
                            name={name}
                            type={inputType}
                            disabled={isDisabled}
                            placeholder={placeholder}
                            withInputGroup={inputGroupContext?.withInputGroup}
                            addonAfter={addonAfter}
                            addonBefore={addonBefore}
                            hasError={hasErrors}
                            style={style}
                            aria-invalid={hasErrors ? 'true' : 'false'}
                            {...propsFromRHF}
                        />
                        {trailingIcon && (
                            <Box
                                as={isPasswordInput ? 'button' : 'span'}
                                type={isPasswordInput ? 'button' : undefined}
                                ref={suffixRef}
                                css={[
                                    tw`absolute inset-y-0 right-0 px-3 flex items-center text-sm`,
                                    !isPasswordInput && tw`pointer-events-none`,
                                    hasErrors &&
                                        tw`focus:(outline-none  border-red-500 ring-red-500 ring-1 rounded-tr-md rounded-br-md)`,
                                ]}
                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                            >
                                <Text as="span" size="sm" color={hasError ? 'red.500' : 'gray.400'}>
                                    {/* sr-only */}
                                    {isPasswordInput && (
                                        <Box as="span" tw="sr-only">
                                            Password Visibility Toggle
                                        </Box>
                                    )}
                                    {trailingIcon}
                                </Text>
                            </Box>
                        )}
                    </Box>
                    {addonAfter && (
                        <AddonAfter
                            display="flex"
                            tw="border-gray-300 border border-l shadow-sm text-sm rounded-r-md text-gray-500 -ml-px mt-1 bg-gray-100 focus-within:(z-10 outline-none ring-1 ring-blue border-blue-500)"
                        >
                            {addonAfter}
                        </AddonAfter>
                    )}
                </Box>
                {!inputGroupContext && (errMsg ?? helperText) && (
                    <Box mt="2">
                        <ErrorMessage text={errMsg ?? helperText} color={hasErrors ? 'red.500' : 'gray.500'} />
                    </Box>
                )}
            </Box>
        );
    }
);

Input.displayName = 'Input';
