import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { BrandedOptions, SelectBaseProps, Branded } from './types';
import BrandedSelectBaseComponent from './BrandedSelectBaseComponent';

// onChange types from Select can be from Primary, Branded, Searchable or Native, and they have different shapes
// We remove it here first and then add it back as BrandedOptions
export type BrandedSelectProps = Omit<SelectBaseProps & Branded, 'variant' | 'onChange'> & {
    onChange?: (option: BrandedOptions) => void;
};

const BrandedSelect: React.FC<BrandedSelectProps> = (props) => {
    const { name, rules, options, onChange } = props;
    const defaultSelectedOption = options.find((option) => option.defaultSelected);

    const formContext = useFormContext();

    const isUsedWithinCertnForm = formContext;

    if (isUsedWithinCertnForm) {
        const { control } = formContext;
        const errMsg = formContext.formState.errors?.[name]?.message;

        return (
            <Controller
                control={control}
                rules={rules}
                name={name}
                defaultValue={defaultSelectedOption?.value}
                render={({ field: { onChange: RHFOnChange } }) => {
                    const onChangeCB = (option: BrandedOptions) => {
                        RHFOnChange(option.value);
                        onChange?.(option);
                    };
                    const baseCompProps = { ...props, onChange: onChangeCB, errorMessage: errMsg };
                    return <BrandedSelectBaseComponent {...baseCompProps} />;
                }}
            />
        );
    }

    return <BrandedSelectBaseComponent {...props} />;
};

BrandedSelect.displayName = 'BrandedSelect';

export default BrandedSelect;
