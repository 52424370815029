import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';
import { ChevronDownIcon } from '@heroicons/react/outline';
import get from 'lodash.get';

// for i18n and iso2 code
import { getDataSet, reduce } from 'iso3166-2-db';
import ReactCountryFlag from 'react-country-flag';
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js';
import countryTelData from 'country-telephone-data';

import { Rules } from '../Form';
import { ErrorMessage } from '../ErrorMessage';
import { toKebabCase, disabledStyles } from '../../core/utils';
import { Locale } from '../../core/types';

import { CountryDropDown, StyledInput } from './styled';

const countryDropdownStyles = tw`flex items-center justify-center space-x-2 bg-gray-50 text-gray-400 text-sm rounded-l-md px-3 py-2 text-base border-gray-300 border sm:text-sm`;

const inputWrapperBaseStyles = tw`z-10 flex w-full text-gray-900 rounded rounded-l-none border border-gray-300 focus-within:border-gray-300 -ml-px disabled:(bg-gray-100 cursor-not-allowed) focus-within:(z-10 ring-1 ring-blue outline-none border-blue)`;

const errorStyles = tw`border-red-500 text-red-500 focus-within:(ring-red-500 border-red-500 text-red-700)`;

export interface PhoneInputProps {
    label: string;
    // hidden for sr-only
    hideLabel?: boolean;
    locale?: Locale;
    name: string;
    defaultCountryCode?: CountryCode;
    defaultValue?: string;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    hasError?: boolean;
    errorMessage?: React.ReactNode;
    rules?: Rules;
    customCountries?: Record<string, { dialCode: string; name: string }>;
}

// Custom function to fix backspace issue: https://github.com/catamphetamine/libphonenumber-js/issues/225
const formatPhoneNumber = (countryCode: CountryCode, value: string | number): string => {
    const valueAsString = value.toString();
    if (valueAsString.includes('(') && !valueAsString.includes(')')) {
        return valueAsString.replace('(', '');
    }
    return new AsYouType(countryCode).input(valueAsString);
};

// A list of known missing countries from the iso3166-2-db library
// You can update this list following this structure. Make sure to include the different language translations
const knownMissingCountries: Record<string, { dialCode: string; name: Record<Locale, string> }> = {
    CW: { dialCode: '599', name: { en: 'Curaçao', fr: 'Curaçao', es: 'Curaçao' } },
};

// Generate the countries object that has similar shape as the one from iso3166-2-db library, taking language into consideration
const getKnownMissingCountries = (lang: Locale): Record<string, { dialCode: string; name: string }> =>
    Object.keys(knownMissingCountries).reduce((acc, curr) => {
        return { ...acc, [curr]: { ...knownMissingCountries[curr], name: knownMissingCountries[curr].name[lang] } };
    }, {});

// Formats the phone based on the country provided.
// If it's an international format (e.g. +1 604-020-2020), grab the national phone part first, then formats it.
// Otherwise, we can just format the phone directly (i.e. 604-020-2020 => (604) 020-2020)
const getNationalPhone = (country: CountryCode, phone?: string): string => {
    if (!phone) return '';
    const nationalNumber = parsePhoneNumber(phone)?.formatNational();

    return new AsYouType(country).input(nationalNumber ?? phone);
};

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
    (
        {
            label,
            hideLabel,
            name,
            rules,
            locale = 'en',
            defaultCountryCode = 'CA',
            defaultValue = '',
            isDisabled = false,
            isReadOnly = false,
            hasError = false,
            errorMessage,
            customCountries = {},
            ...rest
        },
        ref
    ) => {
        const defaultNationalPhone = getNationalPhone(defaultCountryCode, defaultValue);
        const [currentValue, setCurrentValue] = React.useState<{ countryCode: CountryCode; phone: string }>({
            countryCode: defaultCountryCode, // for flags and default dropdown
            phone: defaultNationalPhone,
        });

        const lang = ['en', 'fr', 'es'].includes(locale) ? locale : 'en';

        const customCountriesMap = React.useMemo(
            () =>
                Object.keys(customCountries).reduce((acc, currCountryCode) => {
                    return {
                        ...acc,
                        [currCountryCode]: {
                            iso: currCountryCode,
                            dialCode: customCountries[currCountryCode].dialCode,
                            name: customCountries[currCountryCode].name,
                        },
                    };
                }, {}),
            [customCountries]
        );

        const countriesDB = React.useMemo(() => {
            return { ...reduce(getDataSet(), lang), ...getKnownMissingCountries(lang), ...customCountriesMap };
        }, [customCountriesMap, lang]);

        const countryCodes = React.useMemo(
            () => Object.keys(countriesDB).sort((a, b) => (countriesDB[a].name > countriesDB[b].name ? 1 : -1)),
            [countriesDB]
        );

        // because libphonenumber-js does not have full data, e.g. dialcode for Bouvet Island and Antarctica are missing
        // This function is just for finding the dial code so that when the form is submitted, the dial code will be prefixed
        const getCountryDialCode = React.useCallback(
            (countryCode: CountryCode) => {
                // If custom dial code exists, return it. Otherwise use 'country-telephone-data' library and its country code to
                // find the dialing code
                return (
                    knownMissingCountries[countryCode]?.dialCode ||
                    customCountries[countryCode]?.dialCode ||
                    countryTelData.allCountries.find((country) => country.iso2 === countryCode.toLowerCase())?.dialCode
                );
            },
            [customCountries]
        );

        const id = toKebabCase(label);

        const formContext = useFormContext();
        if (!formContext) {
            throw new Error('Phone Input must be used within a FormProvider');
        }

        let errMsg = errorMessage;

        const { reset, clearErrors, register } = formContext;

        errMsg = formContext.formState.errors?.[name]?.message;

        const hasErrors = Boolean(hasError || errMsg);

        const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            // We disable select using CSS. This is a safeguard to prevent
            // a user from hacking the CSS and changing the value
            if (isDisabled || isReadOnly) return;

            const countryCode = e.target.value as CountryCode;

            // Reset the country code and format
            setCurrentValue({
                phone: '',
                countryCode,
            });

            // Reset phone value
            reset({ [name]: '' });
            clearErrors(name);
        };

        const currentCountryDialCode = getCountryDialCode(currentValue.countryCode);

        // Custom validate function needed as we use `setValueAs` to prefix the input
        // value with country calling code. That's why we need to translate that error
        // logic and use whatever is passed in from the validation object under the "required"
        // field message
        const customValidate = (value: string) => {
            return (
                (value === `+${currentCountryDialCode} ` && get(rules, 'required.message')) ||
                (typeof rules?.validate === 'function' && rules.validate(value)) ||
                // must set to undefined if no validation error so that form can be submitted.
                // Can't be false or ''
                undefined
            );
        };

        return (
            <div ref={ref}>
                <label
                    htmlFor={id}
                    css={[hideLabel && tw`sr-only`, tw`block text-sm font-semibold text-gray-700 mb-1`]}
                >
                    <div tw="flex justify-between">
                        <span>{label}</span>
                    </div>
                </label>
                <div tw="flex">
                    <CountryDropDown tw="relative rounded-l-md">
                        {/* This is hidden below the country flag. Clicking the country flag will trigger this native select */}
                        <select
                            id={id}
                            data-testid={`${name}-country-select`}
                            value={currentValue.countryCode}
                            {...register(`${name}CountryCode`)}
                            onChange={handleCountryChange}
                            aria-disabled={isDisabled ? 'true' : 'false'}
                            disabled={isDisabled}
                            css={[tw`absolute inset-0 opacity-0`, isDisabled && tw`pointer-events-none`]}
                        >
                            {countryCodes.map((isoCode) => (
                                <option data-testid={countriesDB[isoCode].name} key={isoCode} value={isoCode}>
                                    {countriesDB[isoCode].name}
                                </option>
                            ))}
                        </select>
                        {/* Flag component */}
                        <div css={[countryDropdownStyles, isDisabled && disabledStyles]}>
                            {/* This library should have all country flags. Double check the country code if a flag can't be rendered */}
                            <ReactCountryFlag
                                data-testid="country-flag"
                                countryCode={currentValue.countryCode}
                                svg
                                tw="w-6!"
                                title={currentValue.countryCode}
                            />
                            <ChevronDownIcon tw="h-5 w-5" />
                        </div>
                    </CountryDropDown>

                    <div css={[inputWrapperBaseStyles, isDisabled && disabledStyles, hasErrors && errorStyles]}>
                        <div tw="pl-3 pr-1 flex items-center">
                            <span
                                css={[tw`text-sm text-gray-800`, hasErrors && tw`text-red-500`]}
                                data-testid="country-dial-code"
                            >
                                +{currentCountryDialCode}
                            </span>
                        </div>

                        <StyledInput
                            {...rest}
                            readOnly={isReadOnly}
                            data-testid={`${name}-input`}
                            autoComplete="tel-national"
                            disabled={isDisabled}
                            type="tel"
                            value={currentValue.phone}
                            {...register(name, {
                                ...rules,
                                validate: customValidate,
                                setValueAs: (val) =>
                                    `+${currentCountryDialCode} ${isReadOnly ? defaultNationalPhone : val}`,
                                onChange: (e) => {
                                    setCurrentValue({
                                        ...currentValue,
                                        phone: formatPhoneNumber(currentValue.countryCode, e.target.value),
                                    });
                                },
                            })}
                        />
                    </div>
                </div>
                {hasErrors && errMsg && <ErrorMessage text={errMsg} />}
            </div>
        );
    }
);

PhoneInput.displayName = 'PhoneInput';
