import { theme } from 'twin.macro';
import get from 'lodash.get';

// To get the theme object
export const getTheme = (): Record<string, string | number> => theme``;

export const getThemeValueByKey = (key: string): string | undefined => {
    // get the first dot position so it separates the theme key from the token value
    // For example: "colors.gray.500" => "colors" is the "key" and "gray.500" is the "token"
    const firstDotPosition = key.indexOf('.');

    const token = key.substring(firstDotPosition + 1);
    if (token === 'undefined') return undefined;

    // . can also mean it's a decimal token key e.g. "3.5" in spacing
    // Possible that the value contains dot, e.g. the spacing tokens
    // Check if we can convert it into a number. If not, then it's something like "gray.500" etc
    const isTokenANumber = !Number.isNaN(Number(token));

    // Prepping the array for the lodash get function
    const keyArr = [key.substring(0, firstDotPosition), isTokenANumber ? token : token.split('.')];

    // console.log theme to see tailwind values
    const themeValue = getTheme();

    // Flatten the array in case it's a nested array
    const value = get(themeValue, keyArr.flat());

    if (!value) {
        // eslint-disable-next-line no-console
        console.warn('Key:', key, 'does not exist in theme file. Please verify.');
    }
    return value;
};
