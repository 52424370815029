import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { DefaultValues } from '.';
// Need this in order for the `tw` prop to work
// https://github.com/ben-rogerson/twin.macro/issues/51
import {} from 'twin.macro';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
    onSubmit: (data: unknown) => void;
    defaultValues?: DefaultValues;
}

export const Form: React.FC<FormProps> = ({ onSubmit, children, defaultValues }) => {
    const methods = useForm({ defaultValues });
    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div tw="space-x-3.5 space-y-3.5">{children}</div>
            </form>
        </FormProvider>
    );
};

Form.displayName = 'Form';
