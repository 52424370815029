import * as React from 'react';
import { useFormContext, useController, Control, FieldValues } from 'react-hook-form';
import { ToggleContext } from './ToggleContext';
import { ToggleBaseComponent } from './ToggleBaseComponent';
import { Rules } from '../Form';

export type ToggleBaseProps = {
    label: string;
    sublabel?: string;
    description?: string;
    alignToggle?: 'left' | 'right';
    name: string;
    icon?: React.ReactNode;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    uncheckIcon?: React.ReactNode;
    checkIcon?: React.ReactNode;
    rules?: Rules;
    variant?: 'primary' | 'short';
    onChange?: (checked: boolean) => void;
} & Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'checked' | 'onChange'
>;

// can only have either one prop.
type ToggleProps = ToggleBaseProps &
    ({ isChecked?: boolean; defaultChecked?: never } | { isChecked?: never; defaultChecked?: boolean });

export type ToggleWithControllerProps = ToggleProps & {
    control?: Control<FieldValues, Record<string, unknown>>;
};

// When the Toggle is used with <Form />
const ToggleWithController = (props: ToggleWithControllerProps) => {
    const { name, isDisabled, defaultChecked, rules, control } = props;

    const {
        // destructuring ref because we will not need to use it, and we cannot pass it to
        // Headless UI Switch without errors in console. The "Switch" is a plain React FC that
        // cannot take "ref", so we are filtering this out here
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        field: { value, onChange, ref, ...toggleProps },
        formState,
    } = useController({
        name: isDisabled ? '' : name,
        control,
        rules,
        defaultValue: defaultChecked,
    });

    const handleChange = React.useCallback(
        (checked: boolean) => {
            // fire onChange from props
            // eslint-disable-next-line react/destructuring-assignment
            props.onChange?.(checked);
            // fire react hook form onChange from useController
            onChange(checked);
        },
        [onChange, props]
    );

    const ctxValue = React.useMemo(() => {
        return {
            ...props,
            ...toggleProps,
            formState,
            onChange: handleChange,
            isChecked: value,
        };
    }, [formState, handleChange, value, toggleProps, props]);

    return (
        <ToggleContext.Provider value={ctxValue}>
            <ToggleBaseComponent />
        </ToggleContext.Provider>
    );
};

// When the Toggle is used as a standalone component without <Form />
const ToggleWithoutController = (props: ToggleProps) => {
    return (
        <ToggleContext.Provider value={props}>
            <ToggleBaseComponent />
        </ToggleContext.Provider>
    );
};

// Toggle is leveraging Headless UI "Switch" component under the hood, which doesn't expose the React ref
// and that is why we don't have "React.forwardRef" here
export const Toggle: React.FC<ToggleProps> = (props) => {
    const formContext = useFormContext();
    const isUsedWithinCertnForm = formContext;

    if (isUsedWithinCertnForm) {
        return <ToggleWithController {...props} control={formContext.control} />;
    }
    return <ToggleWithoutController {...props} />;
};

Toggle.displayName = 'Toggle';
ToggleWithoutController.displayName = 'ToggleWithoutController';
ToggleWithController.displayName = 'ToggleWithController';
