// Corresponding key name from tailwind config theme
const HEIGHT_THEME_KEY = 'height';

// Mapping of shorthand prop name to full CSS property key
const HEIGHT_MAP = {
    h: 'height',
};

// List of keys from the map
const HEIGHT_KEYS = Object.keys(HEIGHT_MAP);

export default {
    propKeys: HEIGHT_KEYS,
    propMap: HEIGHT_MAP,
    themeKey: HEIGHT_THEME_KEY,
};
