import * as React from 'react';
import type * as CSS from 'csstype';

import {
    Spacing,
    BorderColor,
    BorderRadius,
    BorderWidth,
    Width,
    Height,
    MaxHeight,
    MaxWidth,
    Content,
    AlignItems,
} from '../../core/types';
import StyledBox from './styled';

export type BoxProps = {
    as?: keyof JSX.IntrinsicElements;
    bgColor?: string;
    borderStyle?: CSS.Property.BorderStyle;
    borderRadius?: keyof BorderRadius;
    borderColor?: keyof BorderColor;
    borderWidth?: keyof BorderWidth;
    display?: CSS.Property.Display;
    justifyContent?: Content;
    alignItems?: AlignItems;
} & Spacing &
    Width &
    Height &
    MaxHeight &
    MaxWidth &
    React.HTMLProps<unknown>;

export const Box = React.forwardRef<HTMLDivElement, React.PropsWithChildren<BoxProps>>(
    ({ as, children, bgColor, borderStyle, borderRadius, borderColor, borderWidth, ...rest }, ref) => (
        <StyledBox
            {...rest}
            as={as}
            ref={ref}
            bgColor={bgColor}
            borderRadius={borderRadius}
            borderColor={borderColor}
            borderWidth={borderWidth}
            borderStyle={borderStyle}
        >
            {children}
        </StyledBox>
    )
);

Box.displayName = 'Box';
