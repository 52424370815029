import * as React from 'react';

export const Complete = (props: { alt: string }) => (
    <svg
        {...props}
        role="img"
        aria-label="Complete"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        width="100%"
        height="100%"
        viewBox="0 0 10 10"
    >
        <circle cx={5} cy={5} r={5} fill="#17B893" />
    </svg>
);
