import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

import { Rules } from '../Form';
import { NativeOptions, Label } from './types';
import { labelStyle, errorStyle } from './styles';
import { Box } from '../Box';
import { ErrorMessage } from '../ErrorMessage';
import { disabledStyles } from '../../core/utils';

const nativeStyle = tw`focus:(ring-1 ring-blue outline-none border-blue) mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md`;
const placeholderStyles = tw`text-gray-400`;

type NativeSelectProps = {
    label: Label;
    // hidden for sr-only
    hideLabel?: boolean;
    name: string;
    isDisabled?: boolean;
    options: NativeOptions[];
    placeholder?: React.ReactNode;
    rules?: Rules;
    hasError?: boolean;
    errorMessage?: React.ReactNode;
    onChange?: (option?: unknown) => void;
};

const NativeSelect: React.FC<NativeSelectProps> = (props) => {
    const {
        label,
        rules,
        hideLabel,
        name,
        hasError,
        errorMessage,
        options,
        placeholder,
        isDisabled,
        onChange,
        ...rest
    } = props;
    const defaultSelectedOption = options.find((option) => option.defaultSelected);
    const hasDefaultSelectedOption = Boolean(defaultSelectedOption);

    // This state is to set the default placeholder style (gray text)
    const [isPlaceholderSelected, setIsPlaceholderSelected] = React.useState(!hasDefaultSelectedOption);
    const formContext = useFormContext();
    const isUsedWithinCertnForm = formContext;

    let errMsg = errorMessage;

    let propsFromRHF: UseFormRegisterReturn | undefined;

    if (isUsedWithinCertnForm) {
        const { register } = formContext;
        errMsg = formContext.formState.errors?.[name]?.message;

        // propsFromRHF has a few properties returned from the `register` method
        // and onChange is one of them
        // We spread this into the `select` element
        propsFromRHF = register(name, rules);
    }

    const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;

        onChange?.(event);
        propsFromRHF?.onChange?.(event);
        setIsPlaceholderSelected(Boolean(value === defaultSelectedOption?.value));
    };

    const hasErrors = Boolean(hasError || errMsg);

    return (
        <div>
            <label htmlFor={label} css={[hideLabel && tw`sr-only`, labelStyle]}>
                {label}
            </label>

            <select
                defaultValue={defaultSelectedOption?.value}
                id={label}
                disabled={isDisabled}
                aria-invalid={hasErrors ? 'true' : 'false'}
                css={[
                    nativeStyle,
                    hasErrors && errorStyle,
                    isDisabled && disabledStyles,
                    isPlaceholderSelected && placeholderStyles,
                ]}
                {...rest}
                {...propsFromRHF}
                // After spreading propsFromRHF, we deliberately overwrite the onChange method from propsFromRHF
                // because it's already included in the `onSelectChange` method, where it includes the `onChange`
                // as well as the logic to determine if the selected item is the placeholder. We need to determine
                // that because we need to style the placeholder select as gray
                onChange={onSelectChange}
            >
                <option value="" disabled selected={Boolean(!defaultSelectedOption)}>
                    {placeholder}
                </option>
                {options.map((option) => (
                    <option key={option.value} value={option.value} disabled={option.isDisabled}>
                        {option.label}
                    </option>
                ))}
            </select>
            {errMsg && (
                <Box mt="2">
                    <ErrorMessage text={errMsg} />
                </Box>
            )}
        </div>
    );
};

NativeSelect.displayName = 'NativeSelect';

export default NativeSelect;
