import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { useKey } from 'react-use';
import { Align, FixedSizeList as List } from 'react-window';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { Box } from '../Box';
import { ErrorMessage } from '../ErrorMessage';
import { labelStyle, errorStyle } from './styles';
import { disabledStyles } from '../../core/utils';
import { PrimaryOptions } from './types';
import { PrimarySelectProps } from './PrimarySelect';

export const buttonStyle = tw`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:(outline-none ring-1 ring-blue-500 border-blue-500) sm:text-sm`;

const ListItem = styled.li`
    ${tw`focus:(outline-none text-white bg-blue-500)`}

    &:hover,
    &:focus {
        & .check-icon {
            ${tw`text-white`}
        }
    }
`;

const findIndexOfSelectedValue = (options: PrimaryOptions[], selectedOption: PrimaryOptions) => {
    return options.findIndex((option) => option.value === selectedOption.value);
};

const PrimarySelectBaseComponent: React.FC<PrimarySelectProps> = (props) => {
    const { label, hideLabel, placeholder, hasError, errorMessage, isDisabled, options, alignCheck, onChange } = props;

    const hasPrefix = options.some((option) => option.prefix);
    const alignCheckOnLeft = alignCheck === 'left';

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const hasErrors = Boolean(hasError || errorMessage);

    const defaultSelectedOption = options.find((option) => option.defaultSelected);
    const [selected, setSelected] = React.useState(
        () => defaultSelectedOption ?? { label: placeholder as string, value: '' }
    );

    // Starting at index -1 so when the down arrow key is pressed, it will focus on the first item (index 0)
    const selectedItemIndex = findIndexOfSelectedValue(options, selected);

    // use ref to store count and avoid unnecessary re-render due to state change, also we will need to
    // update focus as soon as we change the focus index.
    const focusIndex = React.useRef(selectedItemIndex === -1 ? 0 : selectedItemIndex);
    // scrollToItem type - copied straight from @types/react-window
    const listRef = React.useRef<HTMLUListElement & { scrollToItem: (index: number, align?: Align) => void }>();
    const itemsRef = React.useRef<HTMLLIElement[]>([]);
    const menuRef = React.useRef<HTMLDivElement>(null);

    const handleSelectItem = React.useCallback(
        (item: PrimaryOptions) => {
            setSelected(item);
            setIsMenuOpen(false);
            onChange?.(item);
            focusIndex.current = findIndexOfSelectedValue(options, item);
        },
        [focusIndex, onChange, options]
    );

    // Register listeners for keystrokes when the dropdown is open
    // Should be able to navigate options through up/down arrows
    useKey(
        'ArrowUp',
        () => {
            if (isMenuOpen && focusIndex.current > 0) {
                focusIndex.current--;
                itemsRef.current[focusIndex.current].focus();
            }
        },
        { event: 'keydown' },
        [isMenuOpen]
    );

    useKey(
        'ArrowDown',
        () => {
            if (isMenuOpen && focusIndex.current < options.length - 1) {
                focusIndex.current++;
                itemsRef.current[focusIndex.current].focus();
            }
        },
        { event: 'keydown' },
        [isMenuOpen]
    );

    // Choose item on Enter key press
    useKey(
        'Enter',
        () => {
            if (isMenuOpen) {
                handleSelectItem(options[focusIndex.current]);
            }
        },
        { event: 'keydown' },
        [isMenuOpen]
    );

    // Dropdown will be closed on Esc key press
    useKey(
        'Escape',
        () => {
            if (isMenuOpen) {
                setIsMenuOpen(false);
            }
        },
        { event: 'keydown' },
        [isMenuOpen]
    );

    // When the dropdown is opened, scroll to the chosen option
    React.useEffect(() => {
        if (isMenuOpen) {
            listRef?.current?.scrollToItem(selectedItemIndex, 'smart');
            itemsRef?.current[focusIndex.current]?.focus();
        }
    }, [isMenuOpen, listRef, selectedItemIndex]);

    // Enable clicking outside of menu to close menu
    React.useEffect(() => {
        const isClickingOutsideOfMenu = (e: MouseEvent) =>
            menuRef.current && !menuRef.current.contains(e.target as Node);

        const handleClickingOutsideOfMenu = (e: MouseEvent) => {
            if (!isMenuOpen) return;

            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isClickingOutsideOfMenu(e)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickingOutsideOfMenu);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handleClickingOutsideOfMenu);
        };
    }, [isMenuOpen]);

    return (
        <div>
            <div css={[hideLabel && tw`sr-only`, labelStyle]}>{label}</div>
            <div tw="mt-1 relative" css={[isDisabled && disabledStyles]}>
                <button
                    type="button"
                    disabled={isDisabled}
                    css={[
                        buttonStyle,
                        hasErrors && errorStyle,
                        selected.label === placeholder && tw`text-gray-400`,
                        tw`focus:(outline-none ring-2 ring-offset-2 ring-blue)`,
                    ]}
                    onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                    }}
                >
                    <span tw="block truncate">{selected.label}</span>
                    <span tw="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon tw="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </button>

                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            ref={menuRef}
                            initial={false}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ ease: 'easeIn', duration: 0.1 }}
                            tw="origin-top-right absolute inset-x-0 w-full mt-2 py-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            aria-label="dropdown menu"
                            role="listbox"
                            tabIndex={0}
                        >
                            <List
                                width="100%"
                                height={options.length < 5 ? options.length * 42 : 200}
                                itemCount={options.length}
                                itemSize={40}
                                itemData={{ items: options }}
                                // @ts-expect-error Can't fix this TS error but this line comes straight from the react-window documentation
                                ref={listRef}
                            >
                                {({ data, index, style }) => {
                                    const { items } = data;
                                    const item = items[index];
                                    const isSelectedItem = item.value === selected.value;
                                    const isDisabledItem = item.isDisabled;

                                    return (
                                        <ListItem
                                            role="option"
                                            aria-selected={isSelectedItem}
                                            aria-disabled={isDisabledItem}
                                            // to disable tabbing to dropdown
                                            tabIndex={-1}
                                            ref={(el: HTMLLIElement) => {
                                                // adding all li elements to the ref
                                                itemsRef.current[index] = el;
                                            }}
                                            key={item.value}
                                            css={[
                                                item.isDisabled && disabledStyles,
                                                tw`text-sm flex items-center hover:(text-white bg-blue-500)`,
                                                isSelectedItem && tw`text-white bg-blue-500`,
                                            ]}
                                            style={style}
                                            onClick={() => {
                                                handleSelectItem(item);
                                            }}
                                        >
                                            <span tw="flex w-full cursor-default select-none relative py-2 pl-3 pr-9">
                                                {item.prefix && (
                                                    <span
                                                        data-testid="primary-select-prefix"
                                                        css={[
                                                            tw`flex-shrink-0 inline-flex justify-center items-center h-6 w-6 rounded-full mr-3`,
                                                        ]}
                                                        aria-hidden="true"
                                                    >
                                                        {item.prefix}
                                                    </span>
                                                )}
                                                <span
                                                    css={[
                                                        tw`block truncate`,
                                                        isSelectedItem ? tw`font-semibold` : tw`font-normal`,
                                                        alignCheckOnLeft && !hasPrefix ? tw`order-2 pl-6` : tw`order-1`, // if there's prefix, checkmark will be aligned to right
                                                        alignCheckOnLeft && isSelectedItem && tw`pl-1`, // overriding the padding left for selected item
                                                    ]}
                                                >
                                                    {item.label}
                                                    {item.subLabel && (
                                                        <span tw="text-gray-500 ml-2 truncate hover:text-blue-300">
                                                            {item.subLabel}
                                                        </span>
                                                    )}
                                                </span>

                                                {isSelectedItem ? (
                                                    <span
                                                        css={[
                                                            tw`flex justify-self-end items-center text-white`,
                                                            alignCheckOnLeft
                                                                ? 'pl-0'
                                                                : tw`absolute inset-y-0 right-2.5`,
                                                        ]}
                                                    >
                                                        <CheckIcon
                                                            className="check-icon"
                                                            tw="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </span>
                                        </ListItem>
                                    );
                                }}
                            </List>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            {hasErrors && (
                <Box mt="2">
                    <ErrorMessage text={errorMessage} />
                </Box>
            )}
        </div>
    );
};

PrimarySelectBaseComponent.displayName = 'PrimarySelectBaseComponent';

export default PrimarySelectBaseComponent;
