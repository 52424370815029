import tw, { TwStyle } from 'twin.macro';
import type { TextSize, Casing, Align, FontWeight } from './Text';

const FONT_WEIGHT_STYLES = {
    semibold: tw`font-semibold`,
    bold: tw`font-bold`,
    regular: tw`font-normal`,
};

const ALIGN_STYLES = {
    center: tw`text-center`,
    right: tw`text-right`,
    justify: tw`text-justify`,
    left: tw`text-left`,
};

const CASING_STYLES = {
    capitalize: tw`capitalize`,
    uppercase: tw`uppercase`,
    lowercase: tw`lowercase`,
    normal: tw`normal-case`,
};

const TEXT_SIZE_STYLES = {
    xs: tw`text-xs`,
    sm: tw`text-sm`,
    base: tw`text-base`,
    lg: tw`text-lg`,
    xl: tw`text-xl`,
    '2xl': tw`text-2xl`,
    '3xl': tw`text-3xl`,
    '4xl': tw`text-4xl`,
};

export const getFontWeightStyles = (fontWeight?: FontWeight): TwStyle | undefined =>
    fontWeight && FONT_WEIGHT_STYLES[fontWeight];

export const getAlignStyles = (align?: Align): TwStyle | undefined => align && ALIGN_STYLES[align];

export const getCasingStyles = (casing?: Casing): TwStyle | undefined => casing && CASING_STYLES[casing];

export const getSizeStyles = (size?: TextSize): TwStyle | undefined => size && TEXT_SIZE_STYLES[size];
