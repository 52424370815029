import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';

import { withMediaQueries } from './responsive';
import { getThemeValueByKey } from './theme';
import { Props } from '../types';
import config from '../config';

type Map = {
    [key: string]: string | string[];
};

type Prop = {
    [key: string]: string | string[];
};

type ValueFormatterFn<T, U> = (str: T) => U;
/**
 *
 * @param values Array of values e.g ['2', '3']
 * @param key the entry point key in the theme file to map values
 * @returns example: ['1rem', '2rem'] or ['0.75rem', undefined, '2rem']
 */
export function parseValues(values: string[] | string, key: string): (string | undefined)[] {
    const parsedPropValues: (string | undefined)[] = [];

    if (Array.isArray(values)) {
        values.forEach((val) => {
            parsedPropValues.push(getThemeValueByKey(`${key}.${val}`));
        });
    } else {
        parsedPropValues.push(getThemeValueByKey(`${key}.${values}`));
    }

    return parsedPropValues;
}

/**
 *
 * @param props Props (key-value pairs) that are related to Spacing.
 * Example: { mx: 2, mt: 4, pb: [2, undefined, 4] }
 * @param map the object mapping of prop key values to the full name e.g. SPACING_MAP
 * @param themeKey the corresponding key in theme. Used for looking up values
 * @param valueFormatterFn normalizer function to parse the key value. For example, gridColumns have values such as "span-1", "span-2",etc
 * and you want to simplify your prop value for your end user so that they can just use "1" for "span-1", then you need a normalizer
 * function parse the values into the format you want
 * @returns  Array of object containing the parsed key and values
 * Example: [{ margin-bottom: ['1rem']}, margin-top: ['1rem'], padding-bottom: ["0.75rem", undefined, '1rem'] ]
 */

type ParsedKeyValues<T, U> = {
    props: Props;
    map: Map;
    themeKey: string | undefined;
    valueFormatterFn?: ValueFormatterFn<T, U>;
};

export function getParsedKeysValues<T, U>({
    props,
    map,
    themeKey,
    valueFormatterFn,
}: ParsedKeyValues<T, U>): {
    [x: string]: (string | undefined)[];
}[] {
    const parsedList = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(props)) {
        const keyFullName = map[key];

        const normalizedValue = valueFormatterFn ? valueFormatterFn(value) : value;
        // if no theme key, that means it's one of the dynamic props that don't
        // have predefined values from tailwind config. E.g. `display` CSS prop
        if (!themeKey) {
            parsedList.push({ [keyFullName as string]: normalizedValue });
            break;
        }

        if (Array.isArray(keyFullName)) {
            keyFullName.forEach((item) => {
                parsedList.push({ [item]: parseValues(normalizedValue, themeKey) });
            });
        } else {
            parsedList.push({ [keyFullName]: parseValues(normalizedValue, themeKey) });
        }
    }

    return parsedList;
}

/**
 *
 * @param props Any props from React component
 * @param keys Keys you want the props to filtered by
 * @returns Props that are filtered by specified keys and the values are truthy
 */
export function getCleanProps(props: Props, keys: string[]): Record<string, string | string[]> {
    const filteredPropsByKeys = pick(props, keys);
    return pickBy(filteredPropsByKeys, Boolean);
}

export function createStyles<T, U>({
    props,
    propKeys,
    propMap,
    themeKey,
    valueFormatterFn,
}: {
    props: Props;
    propKeys: string[];
    propMap: Prop;
    themeKey: string | undefined;
    valueFormatterFn?: ValueFormatterFn<T, U>;
}): string {
    const cleanProps = getCleanProps(props, propKeys);

    const parsedKeysValues = getParsedKeysValues({ props: cleanProps, map: propMap, themeKey, valueFormatterFn });

    const parsedKeysValuesWithMQ = parsedKeysValues.map((value) => withMediaQueries(value));

    return parsedKeysValuesWithMQ.join('');
}

export function getStylesFromProps(props: Props): string {
    // Loop through the config and concat the styles into one string. Then return to the styled component
    const styles = config.reduce((acc, curr) => acc.concat(createStyles({ props, ...curr })), '');

    return styles;
}

export function toKebabCase(str: string): string {
    return str && str.split(' ').join('-').toLowerCase();
}
