import * as React from 'react';
import tw from 'twin.macro';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { motion, AnimatePresence } from 'framer-motion';

import { BrandedSelectProps } from './BrandedSelect';
import { BrandedOptions } from './types';
import { labelStyle } from './styles';
import { disabledStyles } from '../../core/utils';

const BrandedSelectBaseComponent: React.FC<BrandedSelectProps> = (props) => {
    const { options, isDisabled, onChange, hideLabel, label } = props;
    const defaultSelectedOption = options.find((option) => option.defaultSelected);

    if (!defaultSelectedOption) {
        throw new Error('Options for Branded Select must have one default selected option.');
    }

    const [selected, setSelected] = React.useState(defaultSelectedOption);

    return (
        <Listbox
            value={selected}
            disabled={isDisabled}
            onChange={(option: BrandedOptions) => {
                onChange?.(option);

                // setSelected is also needed to control and stored the entire option such that it's easier to render the label in the Listbox.Button
                setSelected(option);
            }}
        >
            {({ open }) => (
                <div tw="space-y-1 flex flex-col place-items-end">
                    <Listbox.Label css={[hideLabel && tw`sr-only`, labelStyle]}>{label}</Listbox.Label>
                    <div tw="relative" css={[isDisabled && disabledStyles]}>
                        <div tw="inline-flex shadow-sm rounded-md divide-x divide-blue-700 justify-self-end">
                            <div tw="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-blue-700">
                                <div tw="relative inline-flex items-center bg-blue-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                                    <CheckIcon tw="h-5 w-5" aria-hidden="true" />
                                    <p tw="ml-2.5 text-sm font-semibold">{selected.label}</p>
                                </div>
                                <Listbox.Button tw="relative inline-flex items-center bg-blue-500 p-2 rounded-l-none rounded-r-md text-sm font-semibold text-white hover:bg-blue-700 focus:(outline-none z-10 ring-2 ring-offset-2 ring-offset-gray-100 ring-blue-500)">
                                    <span tw="sr-only">{label}</span>
                                    <ChevronDownIcon tw="h-5 w-5 text-white" aria-hidden="true" />
                                </Listbox.Button>
                            </div>
                        </div>

                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    initial={false}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ ease: 'easeIn', duration: 0.1 }}
                                >
                                    <Listbox.Options
                                        static
                                        tw="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                        {options.map((option) => (
                                            <Listbox.Option
                                                key={option.label}
                                                value={option}
                                                disabled={option.isDisabled}
                                                css={[tw`relative`, option.isDisabled && disabledStyles]}
                                            >
                                                {({ active }) => {
                                                    const isOptionSelected = selected.value === option.value;
                                                    return (
                                                        <div
                                                            css={[
                                                                active ? tw`text-white bg-blue-500` : tw`text-gray-900`,
                                                                tw`cursor-default select-none relative p-4 text-sm`,
                                                            ]}
                                                        >
                                                            <div tw="flex flex-col">
                                                                <div tw="flex justify-between">
                                                                    <p
                                                                        css={[
                                                                            isOptionSelected
                                                                                ? tw`font-semibold`
                                                                                : tw`font-normal`,
                                                                        ]}
                                                                    >
                                                                        {option.label}
                                                                    </p>
                                                                    {isOptionSelected ? (
                                                                        <span
                                                                            css={[
                                                                                active
                                                                                    ? tw`text-white`
                                                                                    : tw`text-blue-500`,
                                                                            ]}
                                                                        >
                                                                            <CheckIcon
                                                                                tw="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                                {option.subLabel && (
                                                                    <p
                                                                        css={[
                                                                            active
                                                                                ? tw`text-blue-300`
                                                                                : tw`text-gray-500`,
                                                                            tw`mt-2`,
                                                                        ]}
                                                                    >
                                                                        {option.subLabel}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            )}
        </Listbox>
    );
};

BrandedSelectBaseComponent.displayName = 'BrandedSelectBaseComponent';

export default BrandedSelectBaseComponent;
