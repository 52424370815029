// Libraries
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

// Local
import { NotificationProvider } from '../components/Notification';

// Types
import { Colors } from '../core';

type Theme = {
    colorTheme: Colors;
};

type CertnUIProviderProps = {
    children: JSX.Element;
    theme?: Theme;
};

const defaultTheme = { colorTheme: 'green' as Colors };

// CertnUIProvider wraps the base component and is used to wrap various context providers around CertnUI
// **
// StyledThemeProvider
//  pass in custom theme values from CertnUIProvider to styled components
//  at the initial stage of building this we are aiming for 7 custom theme colors already designed,
//  the only value needing to be passed in is colorTheme.
//  we can then use styled components useTheme (or theme prop) to access these values
// **
// NotificationProvider
//  gives the entire application access to the useNotification functions
//  const { addNotification } = useNotificationContext();
//  addNotification({
//      title: 'Plain notification saved!',
//      description: 'This is the default notification.',
//  });
export const CertnUIProvider = ({ children, theme = defaultTheme }: CertnUIProviderProps): JSX.Element => {
    return (
        <StyledThemeProvider theme={theme}>
            <NotificationProvider>{children}</NotificationProvider>
        </StyledThemeProvider>
    );
};
