// Corresponding key name from tailwind config theme
const GRID_ROW_START_THEME_KEY = 'gridRowStart';

// Mapping of shorthand prop name to full CSS property key
const GRID_ROW_START_MAP = {
    rowStart: 'grid-row-start',
};

// List of keys from the map
const GRID_ROW_START_KEYS = Object.keys(GRID_ROW_START_MAP);

export default {
    propKeys: GRID_ROW_START_KEYS,
    propMap: GRID_ROW_START_MAP,
    themeKey: GRID_ROW_START_THEME_KEY,
};
