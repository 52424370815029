import * as React from 'react';
import tw, { TwStyle } from 'twin.macro';
import { getColorThemeStyles, getColorThemeCloseButtonStyles, getXPaddingStyles } from './utils';
import { withTheme } from '../../theme';
import { Colors } from '../../core';

export interface BadgeProps {
    text?: React.ReactNode;
    size?: 'sm' | 'lg';
    variant?: 'primary' | 'rounded';
    colorTheme?: Colors;
    isCloseable?: boolean;
    children?: React.ReactNode;
    paddingSize?: 'sm';
    customFontSize?: '13px';
}

export type MapPropToStyle = {
    [key: string]: TwStyle;
};

export const Badge: React.FC<BadgeProps> = withTheme((props) => {
    const {
        text,
        size = 'sm',
        variant = 'primary',
        isCloseable = false,
        colorTheme,
        children,
        paddingSize,
        customFontSize,
    } = props;

    // Badge visibility (isCloseable)
    const [isVisible, setIsVisible] = React.useState(true);
    const removeBadge = () => setIsVisible(false);

    const isRounded = variant === 'rounded';
    const isSmall = size === 'sm';
    const isCloseableSmall = isCloseable && isSmall;
    const isCloseableLarge = isCloseable && !isSmall;
    const getPaddingSize: MapPropToStyle = {
        sm: tw`p-3`,
    };
    const getCustomFontSize: MapPropToStyle = {
        '13px': tw`font-size[13px]`,
    };

    if (!isVisible) return null;
    return (
        <span
            css={[
                tw`inline-flex items-center font-semibold py-0.5`,
                getXPaddingStyles(isRounded, isSmall),
                colorTheme && getColorThemeStyles(colorTheme),
                isRounded ? tw`rounded-md` : tw`rounded-full`,
                isSmall ? tw`text-xs` : tw`text-sm`,
                isCloseableSmall && tw`pl-2 pr-0.5`,
                isCloseableLarge && tw`pl-2.5 pr-1`,
                paddingSize && getPaddingSize[paddingSize],
                customFontSize && getCustomFontSize[customFontSize],
            ]}
        >
            {children || text}
            {isCloseable && (
                <button
                    type="button"
                    onClick={removeBadge}
                    css={[
                        colorTheme && getColorThemeCloseButtonStyles(colorTheme),
                        tw`flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white`,
                    ]}
                >
                    <span css={tw`sr-only`}>Remove badge</span>
                    <svg css={tw`h-2 w-2`} stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
            )}
        </span>
    );
});

Badge.displayName = 'Badge';
