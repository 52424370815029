import { mapColSpanOrRowSpan } from '../../components/Grid/utils';

// Corresponding key name from tailwind config theme
const GRID_COLUMN_THEME_KEY = 'gridColumn';

// Mapping of shorthand prop name to full CSS property key
const GRID_COLUMN_MAP = {
    colSpan: 'grid-column',
};

// List of keys from the map
const GRID_COLUMN_KEYS = Object.keys(GRID_COLUMN_MAP);

export default {
    propKeys: GRID_COLUMN_KEYS,
    propMap: GRID_COLUMN_MAP,
    themeKey: GRID_COLUMN_THEME_KEY,
    valueFormatterFn: mapColSpanOrRowSpan,
};
