import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import tw from 'twin.macro';
import { XIcon } from '@heroicons/react/solid';
import { useInView } from 'react-intersection-observer';
import { DrawerBody, DrawerWrapper, DrawerHeader } from './styled';
import { Button, ButtonProps } from '../Button';
import { Text } from '../Text';
import { Checks, StatusBadge, TranslatedText as StatusTranslatedText } from '../StatusBadge';
import { OrderStatus } from '../../core';
import { ArrowDown } from '../../icons/components';

export type DrawerTab = {
    id: number;
    name: string;
};

export type DrawerTranslatedText = {
    fullName: string | JSX.Element;
    email: string | JSX.Element;
    linkText: string | JSX.Element;
    header: string | JSX.Element;
    scrollButtonText: string | JSX.Element;
    statusBadgeText: StatusTranslatedText;
};

export type DrawerProps = React.HTMLAttributes<HTMLDivElement> & {
    isOpen: boolean;
    onClose: () => void;
    translatedText: DrawerTranslatedText;
    orderStatus: OrderStatus;
    onViewReport: () => void;
    onStatusBadgeClick: () => void;
    checks: Checks[];
    ScrollForMoreButton: React.ComponentType<ButtonProps>;
    isHeaderVisible?: boolean;
    onTabClick?: (tab: DrawerTab) => void;
    currentTab?: DrawerTab;
    tabs?: DrawerTab[];
    showReportButton?: boolean;
    localization?: 'en' | 'fr';
};

const fmBackdrop = {
    visible: { opacity: 1, animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)' },
    hidden: { opacity: 0, animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)' },
};

// Drawer - designed for web server, uses different text sizing from our Certn UI design specification
export const Drawer = ({
    children,
    isOpen,
    onClose,
    translatedText,
    orderStatus,
    onViewReport,
    onStatusBadgeClick,
    tabs,
    currentTab,
    onTabClick,
    ScrollForMoreButton,
    isHeaderVisible,
    checks,
    showReportButton,
    localization,
}: DrawerProps) => {
    const { ref: inViewRef, inView } = useInView({ triggerOnce: true });
    const headerRef = React.useRef<HTMLDivElement>(null);
    const endOfDrawerRef = React.useRef<HTMLDivElement | null>(null);

    const [isScrollButtonVisible, setIsScrollButtonVisible] = React.useState<boolean>(inView);

    React.useEffect(() => {
        setIsScrollButtonVisible(!inView);
    }, [inView]);

    const scrollToBottom = React.useCallback(() => {
        setIsScrollButtonVisible(false);
        endOfDrawerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [setIsScrollButtonVisible, endOfDrawerRef]);

    const setRefs = React.useCallback(
        (node) => {
            endOfDrawerRef.current = node;
            inViewRef(node);
        },
        [inViewRef]
    );

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="backdrop"
                    data-testid="backdrop"
                    variants={fmBackdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.5)',
                        zIndex: 100,
                    }}
                    onClick={onClose}
                >
                    <DrawerWrapper
                        onClick={(e) => e.stopPropagation()}
                        initial={{ right: -450 }}
                        animate={{
                            right: 0,
                            transition: { ease: 'easeInOut', duration: 0.25 },
                            animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
                        }}
                        exit={{
                            right: -450,
                            transition: { ease: 'easeInOut', duration: 0.25 },
                            animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
                        }}
                        css={tw`flex flex-col`}
                    >
                        {isHeaderVisible && (
                            <DrawerHeader
                                css={tw`border-b flex flex-col flex-shrink-0 border-gray-200`}
                                ref={headerRef}
                            >
                                <div css={tw`border-b border-gray-200 px-4 py-3 flex justify-between`}>
                                    <Text size="sm">{translatedText?.header}</Text>
                                    <Button
                                        variant="control"
                                        colorTheme="red"
                                        aria-label="closeIcon"
                                        size="lg"
                                        css={[tw`px-0 py-0 bg-white rounded-md text-gray-500 focus:outline-none p-0`]}
                                        onClick={onClose}
                                        controlIcon={<XIcon css={tw`h-5 w-5 m-auto`} aria-hidden="true" />}
                                    />
                                </div>
                                <div css={tw`p-4`}>
                                    <Text css={tw`font-semibold`}>{translatedText.fullName}</Text>
                                    <Text size="sm" css={tw`opacity-70 text-gray-600`}>
                                        {translatedText.email}
                                    </Text>
                                </div>
                                <div css={tw`flex justify-center mb-4`}>
                                    <StatusBadge
                                        orderStatus={orderStatus}
                                        translatedText={translatedText.statusBadgeText}
                                        checks={checks}
                                        variant="extended"
                                        onClick={onStatusBadgeClick}
                                        viewReportComponent={
                                            <Button
                                                data-testid="view-report"
                                                variant="link"
                                                onClick={onViewReport}
                                                colorTheme="green"
                                            >
                                                {translatedText.statusBadgeText.viewReport}
                                            </Button>
                                        }
                                        showReportButton={showReportButton}
                                        localization={localization}
                                    />
                                </div>
                                <div>
                                    <nav css={tw`flex w-full px-4`} aria-label="Tabs">
                                        {tabs?.length &&
                                            tabs.map((tab) => (
                                                <button
                                                    type="button"
                                                    key={tab.name}
                                                    data-testid={`tab-${tab.name}`}
                                                    css={[
                                                        tab.id === currentTab?.id
                                                            ? tw`border-gray-800 text-gray-800`
                                                            : tw`border-transparent text-gray-800 opacity-50 hover:text-gray-700 hover:border-gray-300`,
                                                        tw`w-2/4 whitespace-nowrap py-2.5 px-1 border-b-2 text-sm`,
                                                    ]}
                                                    aria-current={tab.id === currentTab?.id ? 'page' : undefined}
                                                    onClick={() => onTabClick?.(tab)}
                                                >
                                                    {tab.name}
                                                </button>
                                            ))}
                                    </nav>
                                </div>
                            </DrawerHeader>
                        )}
                        <DrawerBody css={tw`bg-gray-100 flex flex-col flex-grow p-4 overflow-x-scroll`}>
                            {children}
                            {isScrollButtonVisible && (
                                <>
                                    <ScrollForMoreButton
                                        size="xs"
                                        data-testid="scrollbutton"
                                        onClick={scrollToBottom}
                                        rightIcon={<ArrowDown />}
                                        style={{
                                            borderRadius: '40px',
                                            position: 'absolute',
                                            bottom: '16px',
                                            right: '50%',
                                            transform: 'translate(50%)',
                                            padding: '8px 16px',
                                            fontWeight: 400,
                                            marginRight: 0,
                                        }}
                                    >
                                        {translatedText.scrollButtonText}
                                    </ScrollForMoreButton>
                                    <div ref={setRefs} />
                                </>
                            )}
                        </DrawerBody>
                    </DrawerWrapper>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
