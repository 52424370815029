import * as React from 'react';
import { UseFormStateReturn, FieldValues } from 'react-hook-form';
import { ToggleBaseProps } from './Toggle';

type ToggleContextProps = ToggleBaseProps & {
    formState?: UseFormStateReturn<FieldValues>;
    // here because inside a context, the `isChecked` is based on the RHF value. RFH manages the default value
    // as well as keeping track of the toggle value
    // The `defaultChecked` can also be present for disabled state (disabled state does not use controller so
    // the default value can't be set via `useController`, and has to rely on the `defaultChecked` value)
    isChecked?: boolean;
    defaultChecked?: boolean;
};

export const ToggleContext = React.createContext<ToggleContextProps | undefined>(undefined);
