import * as React from 'react';

export const CarretUp = () => (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.0654 4.57393L5.21696 0.111426C5.10681 -0.0163086 4.8947 -0.0163086 4.78337 0.111426L0.934934 4.57393C0.791965 4.74033 0.920871 4.98408 1.15173 4.98408H8.84861C9.07947 4.98408 9.20837 4.74033 9.0654 4.57393Z"
            fill="#434343"
        />
    </svg>
);
