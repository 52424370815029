import { styled } from 'twin.macro';
import { getThemeValueByKey } from '../../core/utils';

export const Wrapper = styled.div<Partial<{ $color: string; hasLeftAccent: boolean }>>`
    background-color: ${({ $color }) => getThemeValueByKey(`colors.${$color}.50`)};
    ${({ $color, hasLeftAccent }) => hasLeftAccent && `border-color: ${getThemeValueByKey(`colors.${$color}.500`)}`};
`;
export const IconWrapper = styled.div<Partial<{ $color: string }>>`
    color: ${({ $color }) => getThemeValueByKey(`colors.${$color}.500`)};
`;
export const MessageWrapper = styled.div<Partial<{ $color: string }>>`
    color: ${({ $color }) => getThemeValueByKey(`colors.${$color}.700`)};
`;
export const DescriptionWrapper = styled.div<Partial<{ $color: string }>>`
    color: ${({ $color }) => getThemeValueByKey(`colors.${$color}.700`)};
`;
