const DYNAMIC_THEME_KEY = undefined;

const DYNAMIC_MAP = {
    display: 'display',
};

export const DYNAMIC_KEYS = Object.keys(DYNAMIC_MAP);

export default {
    propKeys: DYNAMIC_KEYS,
    propMap: DYNAMIC_MAP,
    themeKey: DYNAMIC_THEME_KEY,
};
