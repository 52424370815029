import * as React from 'react';

export const CarretDown = () => (
    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.34861 0.015625H0.651731C0.420871 0.015625 0.291965 0.259375 0.434934 0.425781L4.28337 4.88828C4.39353 5.01602 4.60564 5.01602 4.71696 4.88828L8.5654 0.425781C8.70837 0.259375 8.57947 0.015625 8.34861 0.015625Z"
            fill="#434343"
        />
    </svg>
);
