import tw, { TwStyle } from 'twin.macro';
import { ButtonVariants } from './Button';
import { ButtonSizes, Colors, BUTTON_SIZE_STYLES } from '../../core';

const LEFT_ICON_STYLES: Record<ButtonSizes, TwStyle> = {
    xs: tw`-ml-0.5 mr-2 h-4 w-4`,
    sm: tw`-ml-0.5 mr-2 h-4 w-4`,
    md: tw`-ml-1 mr-2 h-5 w-5`,
    lg: tw`-ml-1 mr-3 h-5 w-5`,
    xl: tw`-ml-1 mr-3 h-5 w-5`,
};

const RIGHT_ICON_STYLES: Record<ButtonSizes, TwStyle> = {
    xs: tw`ml-2 -mr-0.5 h-2.5 w-2.5`,
    sm: tw`ml-2 -mr-0.5 h-4 w-4`,
    md: tw`ml-2 -mr-1 h-5 w-5`,
    lg: tw`ml-3 -mr-1 h-5 w-5`,
    xl: tw`ml-3 -mr-1 h-5 w-5`,
};

const CONTROL_ICON_STYLES: Record<ButtonSizes, TwStyle> = {
    xs: tw`h-3 w-3 m-auto`,
    sm: tw`h-3 w-3 m-auto`,
    md: tw`h-3 w-3 m-auto`,
    lg: tw`h-5 w-5 m-auto`,
    xl: tw`h-5 w-5 m-auto`,
};

const COLOR_THEME_STYLES: Record<ButtonVariants, Record<Colors, TwStyle>> = {
    control: {
        red: tw`bg-transparent`,
        orange: tw`bg-transparent`,
        yellow: tw`bg-transparent`,
        green: tw`bg-transparent`,
        blue: tw`bg-transparent`,
        purple: tw`bg-transparent`,
        gray: tw`bg-transparent`,
    },
    ghost: {
        red: tw`bg-red-50 hover:bg-red-100 text-red-700 focus:(ring-offset-red-50 ring-red-500)`,
        orange: tw`bg-orange-50 hover:bg-orange-100 text-orange-700 focus:(ring-offset-orange-50 ring-orange-500)`,
        yellow: tw`bg-yellow-50 hover:bg-yellow-100 text-yellow-700 focus:(ring-offset-yellow-50 ring-yellow-500)`,
        green: tw`bg-green-50 hover:bg-green-100 text-green-700 focus:(ring-offset-green-50 ring-green-500)`,
        blue: tw`bg-blue-50 hover:bg-blue-100 text-blue-700 focus:(ring-offset-blue-50 ring-blue-500)`,
        purple: tw`bg-purple-50 hover:bg-purple-100 text-purple-700 focus:(ring-offset-purple-50 ring-purple-500)`,
        gray: tw`bg-gray-100 hover:bg-gray-200 text-gray-700 focus:(ring-offset-gray-100 ring-gray-500)`,
    },
    dismiss: {
        red: tw`bg-red-50 hover:bg-red-100 text-red-700 focus:(ring-offset-red-50 ring-red-500) h-8 w-8 p-0`,
        orange: tw`bg-orange-50 hover:bg-orange-100 text-orange-700 focus:(ring-offset-orange-50 ring-orange-500) h-8 w-8 p-0`,
        yellow: tw`bg-yellow-50 hover:bg-yellow-100 text-yellow-700 focus:(ring-offset-yellow-50 ring-yellow-500) h-8 w-8 p-0`,
        green: tw`bg-green-50 hover:bg-green-100 text-green-700 focus:(ring-offset-green-50 ring-green-500) h-8 w-8 p-0`,
        blue: tw`bg-blue-50 hover:bg-blue-100 text-blue-700 focus:(ring-offset-blue-50 ring-blue-500) h-8 w-8 p-0`,
        purple: tw`bg-purple-50 hover:bg-purple-100 text-purple-700 focus:(ring-offset-purple-50 ring-purple-500) h-8 w-8 p-0`,
        gray: tw`bg-gray-100 hover:bg-gray-200 text-gray-700 focus:(ring-offset-gray-100 ring-gray-500) h-8 w-8 p-0`,
    },
    link: {
        red: tw`text-red underline shadow-none p-0 hover:(bg-transparent text-red-700)`,
        orange: tw`text-orange underline shadow-none p-0 hover:(bg-transparent text-orange-700)`,
        yellow: tw`text-yellow underline shadow-none p-0 hover:(bg-transparent text-yellow-700)`,
        green: tw`text-green underline shadow-none p-0 hover:(bg-transparent text-green-700)`,
        blue: tw`text-blue underline shadow-none p-0 hover:(bg-transparent text-blue-700)`,
        purple: tw`text-purple underline shadow-none p-0 hover:(bg-transparent text-purple-700)`,
        gray: tw`text-gray underline shadow-none p-0 hover:(bg-transparent text-gray-700)`,
    },
    outline: {
        red: tw`text-red-700 border bg-white shadow-sm border-red-300 hover:(bg-red-100)`,
        orange: tw`text-orange-700 border bg-white shadow-sm border-orange-300 hover:(bg-orange-100)`,
        yellow: tw`text-yellow-700 border bg-white shadow-sm border-yellow-300 hover:(bg-yellow-100)`,
        green: tw`text-green-700 border bg-white shadow-sm border-green-300 hover:(bg-green-100)`,
        blue: tw`text-blue-700 border bg-white shadow-sm border-blue-300 hover:(bg-blue-100)`,
        purple: tw`text-purple-700 border bg-white shadow-sm border-purple-300 hover:(bg-purple-100)`,
        gray: tw`text-gray-700 border bg-white shadow-sm border-gray-300 hover:(bg-gray-100)`,
    },
    primary: {
        red: tw`text-white bg-red shadow-sm hover:(bg-red-700)`,
        orange: tw`text-white bg-orange shadow-sm hover:(bg-orange-700)`,
        yellow: tw`text-white bg-yellow shadow-sm hover:(bg-yellow-700)`,
        green: tw`text-white bg-green shadow-sm hover:(bg-green-700)`,
        blue: tw`text-white bg-blue shadow-sm hover:(bg-blue-700)`,
        purple: tw`text-white bg-purple shadow-sm hover:(bg-purple-700)`,
        gray: tw`text-white bg-gray shadow-sm hover:(bg-gray-700)`,
    },
};

export const getLeftIconStyles = (size: ButtonSizes): TwStyle => size && LEFT_ICON_STYLES[size];

export const getRightIconSizeStyles = (size: ButtonSizes): TwStyle => size && RIGHT_ICON_STYLES[size];

export const getSizeStyles = (size: ButtonSizes): TwStyle => size && BUTTON_SIZE_STYLES[size];

export const getColorThemeStyles = (colorTheme: Colors, variant: ButtonVariants): TwStyle =>
    COLOR_THEME_STYLES[variant][colorTheme];

export const getControlIconSizeStyles = (size: ButtonSizes): TwStyle => size && CONTROL_ICON_STYLES[size];
