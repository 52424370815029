// Corresponding key name from tailwind config theme
const GRID_COLUMN_START_THEME_KEY = 'gridColumnStart';

// Mapping of shorthand prop name to full CSS property key
const GRID_COLUMN_START_MAP = {
    colStart: 'grid-column-start',
};

// List of keys from the map
const GRID_COLUMN_START_KEYS = Object.keys(GRID_COLUMN_START_MAP);

export default {
    propKeys: GRID_COLUMN_START_KEYS,
    propMap: GRID_COLUMN_START_MAP,
    themeKey: GRID_COLUMN_START_THEME_KEY,
};
