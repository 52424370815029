// Corresponding key name from tailwind config theme
const GRID_TEMPLATE_ROWS_THEME_KEY = 'gridTemplateRows';

// Mapping of shorthand prop name to full CSS property key
const GRID_TEMPLATE_ROWS_MAP = {
    templateRows: 'grid-template-rows',
};

// List of keys from the map
const GRID_TEMPLATE_ROWS_KEYS = Object.keys(GRID_TEMPLATE_ROWS_MAP);

export default {
    propKeys: GRID_TEMPLATE_ROWS_KEYS,
    propMap: GRID_TEMPLATE_ROWS_MAP,
    themeKey: GRID_TEMPLATE_ROWS_THEME_KEY,
};
