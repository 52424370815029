import * as React from 'react';

export const Waiting = (props: { alt: string }) => (
    <svg
        {...props}
        role="img"
        aria-label="Waiting"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        width="100%"
        height="100%"
        viewBox="0 0 10 10"
    >
        <circle cx={5} cy={5} r={5} fill="#E67D23" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 1.389c.307 0 .555.249.555.555v3.381L7.06 6.83a.556.556 0 0 1-.785.786L4.77 6.111a1.111 1.111 0 0 1-.326-.786v-3.38c0-.307.25-.556.556-.556Z"
            fill="#fff"
        />
    </svg>
);
