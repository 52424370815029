import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';

import Component from './styled';
import { toKebabCase } from '../../core/utils';
import { Rules, generateNameWithPrefix } from '../Form';
import { ErrorMessage } from '../ErrorMessage';

export type RadioGroupProps = React.HTMLAttributes<HTMLDivElement> & {
    name?: string;
    defaultValue?: string;
    rules?: Rules;
};

export type RadioStateManagementProps = {
    radioSelectId: string;
    setRadioSelectId: React.Dispatch<React.SetStateAction<string>>;
};

// RadioGroupContext allows the grouping of radio buttons via a same name, leveraging React Context
export const RadioGroupContext = React.createContext<
    ((RadioGroupProps | undefined) & (RadioStateManagementProps | undefined)) | undefined
>(undefined);

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
    ({ children, id, rules, defaultValue, name, ...rest }, ref) => {
        const formContext = useFormContext();
        const radioGroupName = name ?? generateNameWithPrefix('radio-');
        const [radioSelectId, setRadioSelectId] = React.useState('none');

        const errMsg = formContext?.formState.errors?.[radioGroupName]?.message;
        const value = React.useMemo(() => {
            return { name: radioGroupName, defaultValue, rules, radioSelectId, setRadioSelectId };
        }, [radioGroupName, defaultValue, rules, radioSelectId]);

        return (
            <RadioGroupContext.Provider value={value}>
                <Component
                    {...rest}
                    ref={ref}
                    name={radioGroupName}
                    id={id ?? toKebabCase(radioGroupName)}
                    css={tw`-space-y-px`}
                >
                    {children}
                </Component>
                {errMsg && <ErrorMessage text={errMsg} />}
            </RadioGroupContext.Provider>
        );
    }
);

RadioGroup.displayName = 'RadioGroup';
