import { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { Button } from '../Button';

export const DrawerHeader = styled.div``;
export const DrawerBody = styled.div``;
export const DrawerWrapper = styled(motion.aside)`
    position: fixed;
    z-index: 2;
    pointer-events: all;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    right: 0;
    width: 450px;
`;
export const CustomButton = styled(Button)``;
