import * as React from 'react';

export const Frozen = (props: { alt: string }) => (
    <svg
        {...props}
        role="img"
        aria-label="Frozen"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        width="100%"
        height="100%"
        viewBox="0 0 10 10"
    >
        <circle cx={5} cy={5} r={5} fill="#9B59B6" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2.222c.307 0 .555.249.555.556V5a.556.556 0 0 1-1.11 0V2.778c0-.307.248-.556.555-.556Zm-.556 5c0-.307.25-.555.556-.555h.005a.556.556 0 0 1 0 1.11H5a.556.556 0 0 1-.556-.555Z"
            fill="#fff"
        />
    </svg>
);
