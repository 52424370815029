import tw, { styled } from 'twin.macro';

export const StatusBadgeWrapper = styled.div`
    box-sizing: border-box;
    background: ${tw`bg-white`};
    border-radius: 4px;
`;

export const StatusBadgeWrapperMini = styled(StatusBadgeWrapper)`
    width: 182px;
    min-height: 40px;
    padding: 5px 8px;
`;

export const StatusBadgeWrapperHeader = styled.div``;

export const StatusBadgeWrapperExtended = styled(StatusBadgeWrapper)`
    width: 417px;
    min-height: 65px;
    padding: 12px;
`;

export const BadgeTextWrapper = styled.div``;

export const ReportStatusFontSize16px = styled.div`
    font-size: 16px;
`;

export const ReportStatusFontSize13px = styled.div`
    font-size: 13px;
    line-height: 14px;
`;

export const CheckCompleteFontSize14px = styled.div`
    font-size: 14px;
    line-height: 18.2px;
    margin-left: 22px;
    margin-top: 4px;
    font-weight: normal;
`;

export const CheckCompleteFontSize12px = styled.div`
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
`;

export const CheckCompleteFontSize12pxWithMargin = styled(CheckCompleteFontSize12px)`
    margin-left: 14px;
`;

export const ViewReportLineHeight18px = styled.div`
    line-height: 18px;
`;

export const IconWrapper = styled.div<{ extended?: boolean }>`
    height: ${({ extended }) => (extended ? '16px' : '10px')};
    width: ${({ extended }) => (extended ? '16px' : '10px')};
`;
