import { getTheme } from './theme';

/**
 * @returns Sorted breakpoints from theme file in array
 * ["376px", "640px", "768px", "1024px", "1280px", "1536px"]
 */
export function getSortedBreakPoints(): string[] {
    const { screens } = getTheme();

    return Object.entries(screens)
        .sort(([, a], [, b]) => a - b)
        .map(([, item]) => item);
}

/**
 * @returns Breakpoint value by index
 * Example: console.log(getBreakPointsByIndex(2)) // "768px"
 */
export function getBreakPointsByIndex(idx: number): string {
    const sortedBps = getSortedBreakPoints();

    return sortedBps[idx];
}

export function withMediaQueries(list: { [x: string]: (string | undefined)[] }): string {
    let result = '';

    const mediaStart = (index: number) => `@media (min-width: ${getBreakPointsByIndex(index)}) {`;

    const mediaEnd = '}';

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(list)) {
        if (typeof value === 'string') {
            result += `${key}: ${value};`;
            // eslint-disable-next-line no-continue
            continue;
        }

        // eslint-disable-next-line no-loop-func
        value.forEach((val, idx) => {
            if (val) {
                if (idx === 0) {
                    result += `${key}: ${val};`;
                } else {
                    result += `${mediaStart(idx)} ${key}: ${val} ${mediaEnd};`;
                }
            }
        });
    }

    return result;
}
