import * as React from 'react';

export const ArrowDown = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.68736 4.37469H8.60254C8.54093 4.37469 8.482 4.40148 8.44049 4.44835L5.50879 7.82737V0.285854C5.50879 0.226925 5.46057 0.178711 5.40165 0.178711H4.59807C4.53914 0.178711 4.49093 0.226925 4.49093 0.285854V7.82737L1.55923 4.44835C1.51906 4.40148 1.46013 4.37469 1.39718 4.37469H0.312359C0.221288 4.37469 0.171734 4.48318 0.232002 4.55148L4.67709 9.67425C4.71727 9.7206 4.76695 9.75778 4.82275 9.78326C4.87856 9.80873 4.93918 9.82192 5.00053 9.82192C5.06187 9.82192 5.1225 9.80873 5.17831 9.78326C5.23411 9.75778 5.28379 9.7206 5.32397 9.67425L9.76772 4.55148C9.82798 4.48184 9.77843 4.37469 9.68736 4.37469Z"
            fill="white"
        />
    </svg>
);
