import * as React from 'react';
import { useTheme } from 'styled-components';
import { Colors } from '../core';

type WithThemeProps = {
    colorTheme?: Colors;
};

const getDisplayName = (WrappedComponent: React.FC) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

// HOC to wrap components using colorTheme with styled components theme provider while still allowing direct colorTheme passthrough
export function withTheme<P>(
    // Type the incoming component
    // this creates a union type of whatever the component already accepts and WithThemeProps
    WrappedComponent: React.ComponentType<P & WithThemeProps>
) {
    const WithColorTheme = (props: P & WithThemeProps) => {
        const { colorTheme: propsTheme } = props;
        const { colorTheme: styledColorTheme } = useTheme() ?? { colorTheme: 'green' }; // used when no CertnUIProvider exists
        const colorTheme = propsTheme ?? styledColorTheme;
        // At this point, the props being passed in are the original props the component expects.
        return <WrappedComponent {...props} colorTheme={colorTheme} />;
    };
    WithColorTheme.displayName = `WithColorTheme(${getDisplayName(WrappedComponent as React.FC)})`;
    return WithColorTheme;
}
