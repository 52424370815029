import tw, { styled } from 'twin.macro';
import { CheckboxGroupProps } from '../CheckboxGroup';

const baseStyles = tw`border border-gray-300 rounded h-4 w-4`;
const focusStyles = tw`focus:(bg-green-500 ring-2 ring-offset-2 ring-blue-500)`;
const shadowStyles = tw`shadow-sm`;
const checkedStyles = tw`checked:(bg-green-500 border-transparent)`;
const disabledStyles = tw`disabled:bg-gray-100 disabled:cursor-not-allowed`;
const errorStyles = tw`border-red-300`;

export default styled.input<CheckboxGroupProps & { hasError: boolean }>`
    &&& {
        ${baseStyles};
        ${focusStyles};
        ${shadowStyles};
        ${checkedStyles};
        ${disabledStyles};
        ${({ hasError }) => hasError && errorStyles};
        :focus:not(:checked) {
            ${tw`bg-transparent`}
        }
    }
`;
