// Corresponding key name from tailwind config theme
const MAX_WIDTH_THEME_KEY = 'maxWidth';

// Mapping of shorthand prop name to full CSS property key
const MAX_WIDTH_MAP = {
    maxWidth: 'max-width',
};

// List of keys from the map
const MAX_WIDTH_KEYS = Object.keys(MAX_WIDTH_MAP);

export default {
    propKeys: MAX_WIDTH_KEYS,
    propMap: MAX_WIDTH_MAP,
    themeKey: MAX_WIDTH_THEME_KEY,
};
