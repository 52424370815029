// Corresponding key name from tailwind config theme
const GRID_TEMPLATE_COLUMNS_THEME_KEY = 'gridTemplateColumns';

// Mapping of shorthand prop name to full CSS property key
const GRID_TEMPLATE_COLUMNS_MAP = {
    templateCols: 'grid-template-columns',
};

// List of keys from the map
const GRID_TEMPLATE_COLUMNS_KEYS = Object.keys(GRID_TEMPLATE_COLUMNS_MAP);

export default {
    propKeys: GRID_TEMPLATE_COLUMNS_KEYS,
    propMap: GRID_TEMPLATE_COLUMNS_MAP,
    themeKey: GRID_TEMPLATE_COLUMNS_THEME_KEY,
};
