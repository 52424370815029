// Libraries
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControllerStateAndHelpers } from 'downshift';

// Local modules
import SearchableSelectBaseComponent from './SearchableSelectBaseComponent';

// Types
import { SearchableOptions, SelectBaseProps, Searchable } from './types';

// onChange types from Select can be from Primary, Branded, Searchable or Native, and they have different shapes
// We remove it here first and then add it back as SearchableOptions
export type SearchableSelectProps = Omit<SelectBaseProps & Searchable, 'variant' | 'onChange'> & {
    // types come directly from downshift.js
    onChange?: (
        option?: SearchableOptions | null,
        stateAndHelpers?: ControllerStateAndHelpers<SearchableOptions>
    ) => void;
};

const SearchableSelect: React.FC<SearchableSelectProps> = (props) => {
    const { name, rules, options, onChange } = props;

    const formContext = useFormContext();
    const isUsedWithinCertnForm = formContext;

    if (isUsedWithinCertnForm) {
        const { control, formState } = formContext;
        const errMsg = formState.errors?.[name]?.message;
        const initialSelectedItem = options.find((option) => option.defaultSelected);

        return (
            <Controller
                control={control}
                rules={rules}
                name={name}
                defaultValue={initialSelectedItem?.value}
                render={({ field: { onChange: RHFOnChange }, ...rest }) => {
                    const onChangeCB = (option?: SearchableOptions | null) => {
                        RHFOnChange(option?.value);
                        onChange?.(option);
                    };
                    const baseCompProps = { ...props, ...rest, onChange: onChangeCB, errorMessage: errMsg };
                    return <SearchableSelectBaseComponent {...baseCompProps} />;
                }}
            />
        );
    }

    return <SearchableSelectBaseComponent {...props} />;
};

SearchableSelect.displayName = 'SearchableSelect';

export default SearchableSelect;
