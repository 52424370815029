export default {
    checkStatus: {
        ACTION_REQUIRED: 'ACTION_REQUIRED',
        REQUIRES_MORE_INFORMATION: 'REQUIRES_MORE_INFORMATION',
        UNRESPONSIVE: 'UNRESPONSIVE',
        WAITING_ON_CANDIDATE: 'WAITING_ON_CANDIDATE',
        WAITING_ON_ADJUDICATOR: 'WAITING_ON_ADJUDICATOR',
        IN_PROGRESS: 'IN_PROGRESS',
        COMPLETE: 'COMPLETE',
        IN_DISPUTE: 'IN_DISPUTE',
        CANCELLED: 'CANCELLED',
    },
    orderStatus: {
        WAITING_ON_CANDIDATE: 'WAITING_ON_CANDIDATE',
        APPLICATION_EXPIRED: 'APPLICATION_EXPIRED',
        IN_PROGRESS: 'IN_PROGRESS',
        COMPLETE: 'COMPLETE',
        IN_DISPUTE: 'IN_DISPUTE',
        ACTION_REQUIRED: 'ACTION_REQUIRED',
        CANCELLED: 'CANCELLED',
        active: ['ACTION_REQUIRED', 'IN_PROGRESS', 'COMPLETE', 'IN_DISPUTE'],
    },
};
