import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';
import './font.css';

const CustomStyles = createGlobalStyle`
  body {
    ${tw`font-body text-gray-800`}
  }

  svg {
    vertical-align: baseline
  }
`;

export const GlobalStyles = (): JSX.Element => (
    <>
        <BaseStyles />
        <CustomStyles />
    </>
);
