import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';
import Component from './styled';
import { RadioGroupContext } from '../RadioGroup';
import { toKebabCase } from '../../core/utils';
import { Rules } from '../Form';

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
    value: string;
    hasError?: boolean;
    errorMessage?: React.ReactNode;
    rules?: Rules;
};

const radioGroupStyle = tw`border-gray-200 relative border p-4 z-10 flex first:(rounded-tl-md rounded-tr-md) focus:(bg-green-100) hover:(border-gray-400) last:(rounded-bl-md rounded-br-md) cursor-pointer`;
const selectedRadioGroupStyle = tw`relative border p-4 z-20 border-green-300 flex first:(rounded-tl-md rounded-tr-md) bg-green-100 hover:(border-green-300) last:(rounded-bl-md rounded-br-md) cursor-pointer`;
const singleRadioStyle = tw`flex`;

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
    ({ id, name, rules, hasError, errorMessage, children, defaultChecked, value, ...rest }, ref) => {
        const radioGroupContext = React.useContext(RadioGroupContext);
        const radioRules = radioGroupContext?.rules ?? rules;
        const radioName = radioGroupContext?.name ?? name ?? value;
        const formContext = useFormContext();
        const isUsedWithinCertnForm = formContext;

        const initialValue = radioGroupContext?.defaultValue === value || defaultChecked;

        let errMsg = errorMessage;

        // props from react hook form
        let propsFromRHF = {};

        if (isUsedWithinCertnForm) {
            const { register } = formContext;

            errMsg = formContext.formState.errors?.[radioName]?.message;

            const { ref: rhfRef, ...others } = register(radioName, radioRules);
            propsFromRHF = {
                ref: (e: HTMLInputElement) => {
                    rhfRef(e);
                    if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        (ref as React.MutableRefObject<HTMLInputElement | null>).current = e;
                    }
                },
                ...others,
            };
        }
        const hasErrors = Boolean(hasError || errMsg);

        // Use kebabcased value if id not provided
        const radioId = id ?? toKebabCase(value);

        // Parent-Child state management for selected state
        const handleChange = (target: HTMLInputElement) => {
            radioGroupContext?.setRadioSelectId(target.value);
        };
        // Handle onClick and default selection on radio group
        const isSelected =
            radioGroupContext?.radioSelectId.toLowerCase() === radioId ||
            (initialValue && radioGroupContext?.radioSelectId === 'none');

        const groupStyle = isSelected ? selectedRadioGroupStyle : radioGroupStyle;

        return (
            <>
                <label htmlFor={radioId} css={radioGroupContext ? groupStyle : singleRadioStyle}>
                    <Component
                        {...rest}
                        onClick={(e) => handleChange(e.target as HTMLInputElement)}
                        type="radio"
                        id={radioId}
                        defaultChecked={initialValue}
                        value={value}
                        hasError={hasErrors}
                        name={radioName}
                        {...propsFromRHF}
                    />
                    <span tw="text-sm flex items-center">{children}</span>
                </label>
                {/* Radio group will handle display of error message */}
                {!radioGroupContext && errMsg && (
                    <div tw="mt-2">
                        <ErrorMessage text={errMsg} />
                    </div>
                )}
            </>
        );
    }
);

Radio.displayName = 'Radio';
