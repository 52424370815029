// Corresponding key name from tailwind config theme
const GRID_ROW_END_THEME_KEY = 'gridRowEnd';

// Mapping of shorthand prop name to full CSS property key
const GRID_ROW_END_MAP = {
    rowEnd: 'grid-row-end',
};

// List of keys from the map
const GRID_ROW_END_KEYS = Object.keys(GRID_ROW_END_MAP);

export default {
    propKeys: GRID_ROW_END_KEYS,
    propMap: GRID_ROW_END_MAP,
    themeKey: GRID_ROW_END_THEME_KEY,
};
