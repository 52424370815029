import * as React from 'react';

export const DoubleArrow = () => (
    <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.358.5a.563.563 0 0 0 0 1.125H7.58L1.625 7.58V5.358a.563.563 0 0 0-1.125 0v3.505c0 .352.285.637.637.637h3.505a.563.563 0 0 0 0-1.125H2.42L8.375 2.42v2.222a.563.563 0 0 0 1.125 0V1.137A.638.638 0 0 0 8.863.5H5.358Z"
            fill="#888"
        />
    </svg>
);
