import { GridColSpan, GridRowSpan, GridRowKeys, GridColKeys } from '../../core/types';

/*
 * To convert the colSpan and rowSpan key into the keys in grid-column and grid-row in the theme
 */
export function mapColSpanOrRowSpan(
    span?: GridRowSpan | GridColSpan | (GridRowSpan | GridColSpan)[]
): GridRowKeys | GridRowKeys[] | GridColKeys | GridColKeys[] | undefined {
    if (!span) return;

    if (Array.isArray(span)) {
        return span.map((propVal) => (propVal === 'auto' ? 'auto' : `span-${propVal}`)) as (
            | GridRowKeys
            | GridColKeys
        )[];
    }
    return span === 'auto' ? ('auto' as GridRowKeys | GridColKeys) : (`span-${span}` as GridRowKeys | GridColKeys);
}
