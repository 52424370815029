import * as React from 'react';
import type * as CSS from 'csstype';
import { Spacing, MaxWidth, Content, AlignItems } from '../../core/types';

import { Box } from '../Box';

export type TextAlign = 'left' | 'center';

export type ContainerProps = {
    as?: keyof JSX.IntrinsicElements;
    bgColor?: string;
    textAlign?: TextAlign;
    display?: CSS.Property.Display;
    justifyContent?: Content;
    alignItems?: AlignItems;
} & MaxWidth &
    Spacing;

export const Container: React.FC<ContainerProps> = ({
    as,
    children,
    bgColor,
    textAlign = 'left',
    px = ['4', '8'],
    py = ['8', '14'],
    maxWidth = ['sm', 'md'],
    ...rest
}) => (
    <Box as={as} bgColor={bgColor} css={{ textAlign }} tw="mx-auto" px={px} py={py} maxWidth={maxWidth} {...rest}>
        {children}
    </Box>
);

Container.displayName = 'Container';
