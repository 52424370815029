// Libraries
import * as React from 'react';
import tw from 'twin.macro';

// Utils
import { format } from 'date-fns/esm';
import fr from 'date-fns/esm/locale/fr';
import { enUS } from 'date-fns/esm/locale';
import { OrderStatus, CheckStatus, Colors } from '../../core/types';
import Constants from '../../core/constants';

// Components
import {
    StatusBadgeWrapperMini,
    StatusBadgeWrapperExtended,
    IconWrapper,
    ReportStatusFontSize16px,
    CheckCompleteFontSize14px,
    ViewReportLineHeight18px,
    ReportStatusFontSize13px,
    CheckCompleteFontSize12px,
    StatusBadgeWrapperHeader,
    BadgeTextWrapper,
    CheckCompleteFontSize12pxWithMargin,
} from './styled';
import { Badge } from '../Badge';
import { Text } from '../Text';
import { DoubleArrow, Cancelled, Complete, FullStop, Frozen, InProgress, Waiting } from '../../icons/components';

// We don't have the API schema for how checks will be returned, this may change
export type Checks = {
    status: CheckStatus;
    key: string;
};

export type TranslatedText = {
    of: string | JSX.Element;
    complete: string | JSX.Element;
    submitted: string | JSX.Element;
    orderStatus: string | JSX.Element;
    lastUpdated?: string | JSX.Element;
    viewReport?: string | JSX.Element;
    updated?: string | number | Date;
    checkName?: string | JSX.Element;
};

export type StatusBadgeProps = {
    orderStatus: OrderStatus;
    translatedText: TranslatedText;
    checks?: Checks[];
    viewReportComponent?: JSX.Element;
    onClick?: () => void;
    variant?: 'mini' | 'extended' | 'header';
    showReportButton?: boolean;
    localization?: 'en' | 'fr';
};

const MINI = 'mini';
const EXTENDED = 'extended';
const HEADER = 'header';

// StatusBadge - Designed for web server, uses different text sizing from our Certn UI design specification
export const StatusBadge: React.FC<StatusBadgeProps> = (props) => {
    const {
        checks,
        onClick,
        translatedText,
        orderStatus,
        viewReportComponent,
        variant = MINI,
        showReportButton = true,
        localization = 'en',
    } = props;
    const [numberOfChecksComplete, setNumberOfChecksComplete] = React.useState(0);
    const [numberOfChecksSubmitted, setNumberOfChecksSubmitted] = React.useState(0);
    const [areAnyChecksComplete, setAreAnyChecksComplete] = React.useState(false);
    const [checkCompleteString, setCheckCompleteString] = React.useState('');

    // maintain count if any checks have been completed
    // this will change the result text from `submitted` to `complete` as soon as a check has returned with a result
    React.useEffect(() => {
        setAreAnyChecksComplete(numberOfChecksComplete > 0);
    }, [numberOfChecksComplete, setAreAnyChecksComplete]);

    // monitor check completion
    React.useEffect(() => {
        const completeStatuses = [Constants.checkStatus.COMPLETE, Constants.checkStatus.IN_DISPUTE];
        const checksComplete = checks?.filter((check) => completeStatuses.includes(check.status)) || [];
        setNumberOfChecksComplete(checksComplete.length);
    }, [checks, setNumberOfChecksComplete]);

    // monitor check submission
    React.useEffect(() => {
        const notSubmittedStatuses = [Constants.checkStatus.WAITING_ON_CANDIDATE, Constants.checkStatus.CANCELLED];
        const checksSubmitted = checks?.filter((check) => !notSubmittedStatuses.includes(check.status)) || [];
        setNumberOfChecksSubmitted(checksSubmitted.length);
    }, [checks, setNumberOfChecksSubmitted]);

    // builds the string `2 of 3 complete/submitted` for display
    React.useEffect(() => {
        const checksLength = checks?.length;
        let displayString;
        if (areAnyChecksComplete) {
            displayString = `${numberOfChecksComplete} ${translatedText.of} ${checksLength} ${translatedText.complete}`;
        } else {
            displayString = `${numberOfChecksSubmitted} ${translatedText.of} ${checksLength} ${translatedText.submitted}`;
        }
        if (variant === EXTENDED && translatedText.updated) {
            displayString += ` - ${translatedText.lastUpdated} ${format(
                new Date(translatedText.updated),
                'MMM dd, yyyy',
                { locale: localization === 'fr' ? fr : enUS }
            )}`;
        }
        setCheckCompleteString(displayString);
    }, [
        variant,
        orderStatus,
        localization,
        areAnyChecksComplete,
        numberOfChecksComplete,
        numberOfChecksSubmitted,
        translatedText.complete,
        translatedText.submitted,
        translatedText.updated,
        translatedText.lastUpdated,
        translatedText.of,
        checks?.length,
    ]);

    // orderStatus to icon img binding
    const getIndicatorFromReportStatus = () => {
        const statusToIndicator = {
            [Constants.orderStatus.ACTION_REQUIRED]: <FullStop alt="full stop" />,
            [Constants.orderStatus.CANCELLED]: <Cancelled alt="cancelled" />,
            [Constants.checkStatus.UNRESPONSIVE]: <Frozen alt="froezen" />,
            [Constants.orderStatus.APPLICATION_EXPIRED]: <Cancelled alt="cancelled" />,
            [Constants.orderStatus.IN_DISPUTE]: <Frozen alt="froezen" />,
            [Constants.orderStatus.IN_PROGRESS]: <InProgress alt="in progress" />,
            [Constants.orderStatus.WAITING_ON_CANDIDATE]: <Waiting alt="waiting" />,
            [Constants.checkStatus.WAITING_ON_ADJUDICATOR]: <Waiting alt="waiting" />,
            [Constants.checkStatus.REQUIRES_MORE_INFORMATION]: <Waiting alt="waiting" />,
            [Constants.orderStatus.COMPLETE]: <Complete alt="complete" />,
        };
        return statusToIndicator[orderStatus];
    };

    // order Status to badge color
    const getBadgeColorFromOrderStatus = (): Colors => {
        const orderStatusToBadgeColor = {
            [Constants.checkStatus.ACTION_REQUIRED]: 'red',
            [Constants.checkStatus.CANCELLED]: 'red',
            [Constants.checkStatus.UNRESPONSIVE]: 'purple',
            [Constants.checkStatus.IN_DISPUTE]: 'purple',
            [Constants.checkStatus.IN_PROGRESS]: 'blue',
            [Constants.checkStatus.WAITING_ON_CANDIDATE]: 'orange',
            [Constants.checkStatus.WAITING_ON_ADJUDICATOR]: 'orange',
            [Constants.checkStatus.REQUIRES_MORE_INFORMATION]: 'orange',
            [Constants.checkStatus.COMPLETE]: 'green',
        };

        return orderStatusToBadgeColor[orderStatus] as Colors;
    };

    // mini variant
    if (variant === MINI)
        return (
            <StatusBadgeWrapperMini
                css={[tw`cursor-pointer border rounded flex flex-col justify-between`]}
                onClick={onClick}
            >
                <div css={[tw`flex items-center`]}>
                    <IconWrapper>{getIndicatorFromReportStatus()}</IconWrapper>
                    <BadgeTextWrapper css={[tw`flex-1 ml-1 leading-3`]}>
                        <ReportStatusFontSize13px css={[tw`font-semibold`]}>
                            {translatedText.orderStatus}
                        </ReportStatusFontSize13px>
                    </BadgeTextWrapper>
                    <DoubleArrow />
                </div>
                <CheckCompleteFontSize12pxWithMargin css={[tw`text-gray-500`]}>
                    {checkCompleteString}
                </CheckCompleteFontSize12pxWithMargin>
            </StatusBadgeWrapperMini>
        );

    // header variant
    if (variant === HEADER)
        return (
            <StatusBadgeWrapperHeader>
                <Badge colorTheme={getBadgeColorFromOrderStatus()} variant="rounded" css={tw`rounded-sm`}>
                    <IconWrapper>{getIndicatorFromReportStatus()}</IconWrapper>
                    <Text size="xs" css={tw`font-semibold text-gray-700 ml-1`}>
                        {translatedText.orderStatus}
                    </Text>
                </Badge>

                <div css={tw`mt-2`}>
                    <Text css={tw`font-semibold`}>{translatedText.checkName}</Text>
                    {checks && checks.length > 1 && (
                        <CheckCompleteFontSize12px css={[tw`text-gray-500`]}>
                            {checkCompleteString}
                        </CheckCompleteFontSize12px>
                    )}
                </div>
            </StatusBadgeWrapperHeader>
        );

    // extended variant
    return (
        <StatusBadgeWrapperExtended css={[tw`border rounded`]}>
            <div css={[tw`flex items-center`]}>
                <IconWrapper extended>{getIndicatorFromReportStatus()}</IconWrapper>
                <BadgeTextWrapper css={[tw`flex-1 ml-1.5 leading-5`]}>
                    <ReportStatusFontSize16px css={[tw`font-semibold`]}>
                        {translatedText.orderStatus}
                    </ReportStatusFontSize16px>
                </BadgeTextWrapper>
                {showReportButton && (
                    <ViewReportLineHeight18px data-testid="view-report-button">
                        {viewReportComponent}
                    </ViewReportLineHeight18px>
                )}
            </div>
            <CheckCompleteFontSize14px css={[tw`text-gray-500`]}>{checkCompleteString}</CheckCompleteFontSize14px>
        </StatusBadgeWrapperExtended>
    );
};
