import * as React from 'react';
import tw from 'twin.macro';
import StyledLink from './styled';
import { getColorThemeStyles } from './utils';
import { Colors } from '../../core';
import { withTheme } from '../../theme';

export type LinkFontWeight = 'regular' | 'semibold' | 'bold';

export interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    variant?: LinkFontWeight;
    colorTheme?: Colors;
    hasUnderline?: boolean;
    hasArrow?: boolean;
    isExternal?: boolean;
}

export const Link = withTheme(
    React.forwardRef<HTMLAnchorElement, LinkProps>(
        ({ hasUnderline, colorTheme, variant, hasArrow, isExternal = false, children, ...rest }, ref) => {
            return (
                <StyledLink
                    variant={variant}
                    target={isExternal ? '_blank' : undefined}
                    rel={isExternal ? 'noopener noreferrer' : undefined}
                    ref={ref}
                    css={[tw`text-black`, hasUnderline && tw`underline`, colorTheme && getColorThemeStyles(colorTheme)]}
                    {...rest}
                >
                    {children} {hasArrow && <span aria-hidden="true">&rarr;</span>}
                </StyledLink>
            );
        }
    )
);

Link.displayName = 'Link';
